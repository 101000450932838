.info {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &_mobile {
    margin-bottom: 0;
  }

  &__topicContainer {
    min-width: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
    column-gap: 4.4px;
    row-gap: 4.5px;

    &_expanded {
      flex-direction: column;
      align-items: flex-start;
    }

    > :first-child {
      flex-shrink: 0;
    }
  }

  &__topic {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &_expanded {
      text-overflow: unset;
      overflow-wrap: break-word;
    }
  }

  &__description {
    padding: 0 30px;
    padding-bottom: 24px;

    &_mobileExpanded {
      margin-top: -8px;
    }

    > span {
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }
}

.materials {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__file {
    margin-top: 8px;
    height: 32px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 9999px;
    background-color: #efefef;
    cursor: pointer;
  }

  &__fileName {
    padding: 20px;
    cursor: default;

    & > :nth-child(1n) {
      margin-top: 15px;
    }
  }
}

.tab {
  margin-bottom: 0 !important;

  &_mobile {
    width: 100%;
  }

  &__trigger {
    height: auto !important;
    padding: 24px 30px !important;
    padding-bottom: 20px !important;
    min-height: 70px;
  }
}

.closedStatus {
  margin-bottom: -4px;
}
