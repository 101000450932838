.content {
  margin-top: 30px;
  background-color: var(--color-white);
  min-height: calc(100vh - 400px);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.head {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  &_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
  }
  &_icon {
    width: 342px;
    height: 91px;
    display: flex;
    & > :nth-child(1) {
      margin-right: 20px;
    }
  }
}

.gos {
  &_image {
    width: 91px;
    & img {
      width: 90px;
      height: 90px;
    }
  }
  &_title {
    display: flex;
    align-items: center;
  }
}

.boxes {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 50px;
  justify-content: space-between;
  border-bottom: #d7dced solid 1px;
  column-gap: 20px;
  padding-right: 50px;
  &_item {
    padding-left: 40px;
    width: 100%;
    margin-bottom: 50px;
  }
  &_li {
    display: flex;
    margin-top: 16px;
  }
  &_img {
    width: 20px;
    margin-left: -30px;
    margin-right: 14px;
  }
}

.info {
  margin-left: 40px;
  margin-top: 50px;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
}

.block {
  &_image {
    min-width: 45%;
    margin-left: 30px;
  }
  &_text {
    margin-bottom: 20px;
    min-width: 45%;
  }
}

.item_image {
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  & img {
    width: 85%;
    border-radius: 10px;
  }
}
