.Card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(199, 205, 230, 0.7);
  border-radius: 12px;
  overflow: hidden;
  word-break: break-all;
  margin: 0 0.5% 25px;
  &__educationalPromotionImageWrapper {
    width: 100%;
    aspect-ratio: 620/877;
    overflow: hidden;
  }
  &__eventImageWrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: fill;
    object-position: top center;
  }

  &__content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__title {
    min-height: 42px;
    overflow: hidden;
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
