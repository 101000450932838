.pagination {
  border-top: 1px solid var(--color-gray-200);
  padding-top: 20px;

  &__control {
    padding: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    border-radius: 100px;
    color: var(--color-gray-600);

    &[data-active] {
      color: var(--color-green);
      background: none;
      transition: background 0.2s ease;
      font-weight: 600;

      &:not([data-disabled]):hover {
        background-color: var(--color-gray);
      }
    }

    &:hover {
      background-color: var(--color-gray);
    }

    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }
}
