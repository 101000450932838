.row {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cell_section {
  cursor: pointer;
}
.section_row {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .arrow_drop {
    transform: rotate(180deg);

    &__opened {
      transform: rotate(0deg);
    }
  }

  &_title {
    display: inline-block;
    margin-left: 16px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    // @extend .font-semibold;
  }
}

.createTaskBtn {
  margin-left: 12px;
}
