@import "../components/variables";

.category_btn {
  color: $gray-darker;
  margin-right: 12px;
  margin-top: 10px;
  border: none;
  @extend .small-colorful-btn;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 3px;

  .tag_item {
    color: $gray-darker;
    cursor: pointer;
    margin-right: 12px;

    &.active {
      color: $green;
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 480px) {
  .categories,
  .tags {
    padding: 0 10px;
  }
}
