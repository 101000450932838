.content {
  &__head {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &__deliveryInfo {
    margin-top: 10px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.select_post {
  width: 300px;
  font-weight: 400 !important;
  font-size: 14px !important;
  background-color: var(--color-gray) !important;
}

.styleLabel {
  max-width: 300px;
}
