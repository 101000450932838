.box {
  &_red {
    background-color: var(--color-red-row);
  }
  &_orange {
    background-color: var(--color-orange-row);
  }
  &_yellow {
    background-color: var(--color-yellow-row);
  }
  &_green {
    background-color: var(--color-green-row);
  }
}

.cell_border:not(:last-child) {
  border-right: 1px solid rgb(224, 224, 224);

  .resizer {
    z-index: 10;
    position: absolute;
    right: -5px;
    top: 0;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    background-color: transparent;
  }
}

.status {
  height: 36px;
  width: min-content;
  white-space: nowrap;
  margin: 0 auto;
  padding: 0 12px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  color: var(--color-white);
  cursor: pointer;
  &.s1 {
    background-color: var(--color-blue);
  }
  &.s2 {
    background-color: var(--color-blue-light);
  }
  &.s3 {
    background-color: var(--color-green);
  }
  &.s4 {
    background-color: var(--color-purple);
  }
  &.s5 {
    background-color: var(--color-purple);
  }
  &.s6 {
    background-color: var(--color-green);
  }
  &.s7 {
    background-color: var(--color-gray);
    color: var(--color-dark);
  }
}

.arrow_drop__opened {
  transform: rotate(180deg);
}

.cell_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  > * {
    cursor: pointer;
  }
  img: {
    height: 21px !important;
    width: 21px !important;
  }
}
