.table_head {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}

.head_title {
  background-color: #ffffff;
  padding: 10px 30px;
}

.title {
  display: flex;
  cursor: pointer;
}

.arrow {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow_drop__opened {
  transform: rotate(180deg);
}
