@import "variables.scss";

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  border: none;
  border-bottom: 1px solid $gray;
  padding: 5px 0;
  color: $green;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-transform: capitalize;
  text-decoration: none !important;
}

.react-calendar__tile--active {
  border: 1px solid $green !important;
  border-radius: 5px !important;
}

.react-calendar__tile {
  height: 64px;
  .event-counter {
    display: flex !important;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 17px !important;
    height: 17px !important;
    border-radius: 50%;
    color: $green;
    font-size: 12px !important;
    line-height: 12px !important;
    border: 1px solid $green;
  }
}

.react-calendar__navigation__label {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  position: absolute;
  top: 25px;
  opacity: 0;
  transition: opacity 250ms;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  left: 24px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  right: 24px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.5;
}
