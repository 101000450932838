.Subheader {
  display: inline-block;
  line-height: 30px;
  letter-spacing: 0em;
  font-size: 22px;
  font-family: var(--font-ssp);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Subheader {
    font-size: 16px;
    line-height: 19px;
  }
}

@media screen and (max-width: 767px) {
  .Subheader {
    font-size: 16px;
    line-height: 19px;
  }
}
