.table {
  &_row {
    &_center {
      display: flex;
      font-size: 13px;
      text-align: center;
      line-height: 16px;
      display: flex;
      font-size: 13px;
      text-align: left;
      line-height: 16px;
      min-width: 40px;
      max-height: 47px;
      overflow: hidden;
    }
  }
}
