.modal {
  height: 300px;
  width: 500px;
  max-width: calc(100% - 48px);
  padding: 32px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 12px;

  &__icon {
    width: 80px;
    height: 70px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
  }

  &__secondaryAction {
    color: #373737;
    background: #fff;
    border: 1px solid #e2e5f1;

    &:hover {
      color: #fff;
      background: #00b856;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    height: 264px;
  }
}
