@import "../../components/variables";

.department_top_panel {
  margin-top: 16px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  row-gap: 6px;
  flex-wrap: wrap;
  @extend .standard-content-padding;
}

.wrapperRow {
  min-width: 400px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  &:hover {
    color: var(--color-green);
  }
}

.wrapperLabel {
  cursor: pointer;
}

.departments_table {
  thead tr td:first-child {
    display: flex;
    align-items: center;
    gap: 48px;
  }

  tbody tr td:first-child {
    display: flex;
    align-items: center;
  }

  tbody tr td:first-child label {
    width: 100%;
  }

  .importEmployeesActions {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 20px;
  }

  .positionMissingPrograms {
    background-color: var(--color-red-row);

    &__message {
      margin-right: 24px;
    }
  }

  tr td {
    .signatures_list_btn {
      width: auto;
      height: 36px;
      padding: 0 20px;
      background: #fff;
      @extend .colorful-btn;
    }
  }
}

.department_actions_col {
  cursor: default;
  width: max-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: right;

  &__icon {
    margin-left: 18px;
    cursor: pointer;
  }
}

.red {
  color: $red;
}

.positionFilter {
  margin: 24px;
  margin-top: 0;
}
