.AddEmployeesModal {
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 4px;

  &__content {
    padding: 30px;
    padding-right: 50px;
  }

  &__programs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__select {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__selectedList {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__selectedListContent {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__addButton {
    margin-top: 10px;
  }
}
