.error {
  &__container {
    padding: 16px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    color: #770909;
  }
}
