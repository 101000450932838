@import "@scss/components/variables";

.auth_container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
}

.newsItem {
  width: 400px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
}

.auth_section {
  width: 50%;
  height: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;

  &:nth-child(2) {
    background-color: $green;
  }

  &:first-child {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .auth_section_content {
      .welcome_text {
        font-family: $ff-pf-light;
        font-size: 30px;
        line-height: 38px;
        color: $dark;
        margin-top: 40px;
      }

      .auth_form {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        max-width: 600px;

        .action_buttons {
          .action_button {
            font-family: $ff-ssp;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $gray-darker;

            &:focus {
              outline: none;
            }

            &:first-child {
              margin-right: 15px;
            }

            &.active {
              font-size: 30px;
              line-height: 38px;
              color: $dark;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .auth_container {
    flex-wrap: wrap;
  }

  .auth_section {
    width: 100%;

    &:first-child {
      min-height: 90vh;
      padding: 50px 0;
    }

    &:nth-child(2) {
      min-height: 10vh;
      height: 10vh;
    }
  }
}

@media (max-width: 768px) {
  .auth_section {
    &:first-child {
      padding: 50px 15px;
    }
  }
}

@media (max-width: 480px) {
  .auth_section {
    &:first-child {
      padding: 50px 10px;

      .auth_section_content {
        .welcome_text {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }
}
