@import "../../../scss/components/variables";

.cell {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
}
.nameDoc {
  margin-left: 20px;
}
.signatures_list_btn {
  height: 36px;
  padding: 0 20px;
  width: 170px;
  background: #fff;
  @extend .colorful-btn;
}
.username {
  margin-left: 20px;
}

.filed {
  cursor: pointer;
  width: 500px;
  display: flex;
  align-items: center;
  & > :nth-child(1) {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.employee_control_col {
  display: flex;
  align-items: center;
  float: right;

  img {
    margin-left: 15px;
    cursor: pointer;
  }
}

.archive {
  margin-right: 10px;
}
