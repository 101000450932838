.Subtitle {
  display: inline-block;
  font-size: 30px;
  line-height: 35px;
  font-family: var(--font-ssp);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Subtitle {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .Subtitle {
    font-size: 18px;
    line-height: 18.5px;
  }
}
