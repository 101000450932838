@import "../../components/variables";
.content {
  position: relative;
  padding: 24px;
  margin-bottom: 200px;
  background-color: #fff;

  width: 100%;
  height: min-content;
  border-top-left-radius: 15px;
  -webkit-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  margin-top: 30px;
  font-family: $ff-ssp;
  font-size: 16px;
  color: $dark;
  font-weight: normal;
}

.choise_programm {
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0;
  & > :nth-child(1n) {
    margin-bottom: 10px;
    margin-right: 8px;
  }
}

.table_wrapper {
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 6px;
  }
}

.page_warning {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: var(--color-red);
  & > :nth-child(1n) {
    margin-right: 10px;
  }
}

.modalConfirm {
  cursor: default;
  width: 390px;
  &_head {
    height: 30px;
    width: 100%;
  }
  &_text {
    margin-top: 30px;
  }
  &_check {
    margin-top: 20px;
  }
  &_buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.buttonYes {
  width: 90px;
  margin-right: 20px;
}
.tabs {
  height: 35px;
  border-radius: 35px;
  border: 2px solid #ced1d6;
  text-transform: "normal";
  font-family: "Source Sans Pro";
  color: "#ffffff";
  display: flex;
  overflow: hidden;
  & > :nth-child(1n) {
    min-width: 160px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &_active {
    cursor: default;
    border-radius: 35px;
    background-color: #00b856;
    color: #ffffff;
  }
}

.wrapper {
  margin-top: 36px;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
}

.head {
  position: absolute;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &_filter {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    & > :not(:last-child) {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

.modules_table {
}

.table {
  width: 400px;
  width: calc(100vw - 340px);
  max-width: 1570px;
  overflow-x: auto;
  &_name {
    margin-top: 48px;
    margin-bottom: 24px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: $green;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.direction {
  width: fit-content;
  border-bottom: $green dashed 1px;
  height: 45px;
  margin-top: 28px;
  margin-bottom: 10px;
  overflow: hidden;
}

.direction_list {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 16px;

  &_dowload {
    & > :nth-child(1) {
      margin-right: 10px;
    }
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $green;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    cursor: pointer;
  }
}

.select_direction {
  color: $green !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  background-color: #ffffff !important;
  max-height: 50px !important;
  height: 50px !important;
  & > :nth-child(1) {
    padding-left: 0px !important;
    margin-top: -20px !important;
  }
  & :hover {
    background-color: #ffffff !important;
  }
  & :focus {
    background-color: #ffffff !important;
  }
}

.select_date {
  padding-top: 20px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  background-color: $gray-dark;
  border-bottom: #00000069 solid 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  width: 206px;
  height: 54px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  &:hover {
    border-bottom: #000000c7 solid 1px;
  }
}

.select_label {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 13px;
  color: #5f5f5fdd;
}

.select_icon {
  margin-right: 10px;
  margin-bottom: 10px;
}

.selectRequire {
  display: flex;
  height: 55px !important;
  font-weight: 400;
  padding: 0px 15px;
  color: black;
  font-size: 16px;
  background-color: #eff1f8;
  border-radius: 4px;
  align-items: center;
  &+ul {
    top: 77% !important;
    left: 0% !important;
  }
}