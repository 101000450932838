.table_row {
  &_mini {
    width: fit-content;
    max-width: 120px;
  }
  &_center {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    min-height: 40px;
    min-height: 65px;
    margin-left: -20px;
  }
  &_title {
    min-width: 150px;
    width: 100%;
    margin-right: 20px;
  }
}

.edit_and_save {
  display: flex;
}
