.wrapper {
  overflow: hidden;
}
.list {
  label {
    padding: 5px 100px 5px 24px;
    border-bottom: 1px solid #e6e8ea;
    margin: 0 !important;
    span:nth-child(2) {
      width: 320px !important;
      // padding-right: 50px !important;
      text-align: left !important;
      // background-color: grey;
    }
  }
  max-height: 500px !important;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.title {
  padding-left: 24px;
  margin: 30px 0 20px 0;
}
