.category {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__createFactorBtn {
    margin-top: 30px;
  }
}

.ADD {
  background-color: var(--color-green-row);
}

.EDIT {
  background-color: var(--color-yellow-row);
}

.DELETE {
  background-color: var(--color-red-row);
}
