.modal {
  max-width: 450px;
  width: 100%;
  align-items: flex-start;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
