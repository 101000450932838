.feedbackContainer {
  margin-top: 30px;
  background-color: #fff;
  border-radius: 15px;
  min-height: calc(100vh - 350px);
  padding: 30px;
  width: 100%;

  &__breadcumps {
    margin-bottom: 24px;
    padding-left: 8px;
  }
}

.modal {
  width: calc(100% - 48px);
  min-width: 320px;
  max-width: 480px;
  padding: 24px 28px;
  padding-top: 36px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  min-height: 310px;

  &_large {
    height: 70vh;
  }

  &__title {
    width: 100%;
    overflow-wrap: break-word;
    margin-bottom: 20px;
    display: block;
    text-align: left;
  }
}

.chat {
  width: 100%;
  margin-top: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
  }

  &__messagesContainer {
    height: calc(100vh - 650px);
    box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.2);
    flex-grow: 1;
    position: relative;
    width: 100%;
    margin-top: 8px;
    border-radius: 5px;
    overflow: hidden;
  }

  &__seeMore {
    color: var(--color-green) !important;
    transition: color 70ms ease-out;
    cursor: pointer;

    &:hover {
      color: var(--color-green-darker) !important;
    }
  }
}

.messages {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;

  &__noMessages {
    padding-top: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
  }
}

@media screen and (max-width: 768px) {
  .feedbackContainer {
    min-height: calc(100vh - 95px);
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 8px;
    padding-top: 16px;
  }

  .chat {
    &__messagesContainer {
      height: calc(100vh - 400px);
      margin-bottom: 86px;
    }
    margin-bottom: 0;
  }
}
