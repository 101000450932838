.wrapper {
  margin-top: 20px;
  display: flex;
}

.userInfo {
  width: 300px;
  padding: 14px;
  background-color: var(--color-white);
  border-radius: 10px;
  height: calc(100vh - 250px);
  overflow-y: auto;
}
.companies {
  background-color: var(--color-white);
  border-radius: 10px;
  width: 100%;
  margin-left: 20px;
  height: calc(100vh - 250px);
  padding: 14px;
}

.profile_img_container {
  width: 135px;
  height: 135px;
  margin: 28px 0;
  position: relative;
  cursor: pointer;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-white);
    border-radius: 50%;
    transition: opacity 250ms;
    opacity: 0;
    z-index: 2;
  }

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity 250ms;
    // border-radius: 9999px;
  }

  &:hover:before {
    opacity: 0.8;
  }

  &:hover:after {
    opacity: 0.5;
  }

  &:hover .edit_icon {
    opacity: 1;
  }

  .edit_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 250ms;
    z-index: 2;
  }

  .profile_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // border-radius: 9999px;
  }
}

// .profile_content {
//     width: 100%;
//     display: flex;
//     margin-top: 30px;
//     & > :not(:last-child) {
//         margin-right: 20px;
//     }

//     .profile_content_org {
//         min-height: 600px;
//     }
//     .tarrif_title {
//         color: $gray-dark2;
//         white-space: nowrap;
//     }

//     .profile_content_item {
//         min-width: 300px;
//         height: max-content;
//         width: 100%;
//         background-color: #fff;
//         padding: 20px;
//         font-family: $ff-ssp;
//         font-style: normal;
//         font-weight: 600;
//         font-size: 18px;
//         color: $dark;
//         @extend .border-radius;

//         .cancel_btn {
//             width: 100%;
//             height: 40px;
//             @extend .white-btn;
//         }

//         .user_info {
//             .user_info_item {
//                 font-family: $ff-ssp;
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 17px;
//                 color: $dark;

//                 span {
//                     font-weight: bolder;
//                 }
//             }

//             .edit_data_btn {
//                 width: 100%;
//                 margin-top: 24px;
//                 @extend .white-btn;
//             }
//         }

//         &:nth-child(2) {
//             display: flex;
//             flex-direction: column;
//         }

//         .password_section_content {
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             margin-top: 24px;
//         }

//         .edit_password_btn {
//             @extend .white-btn;
//         }

//         .password_mask {
//             font-family: $ff-ssp;
//             font-style: normal;
//             font-weight: 600;
//             font-size: 16px;
//             line-height: 20px;
//             color: $dark;

//             p {
//                 display: inline;
//                 margin-left: 10px;
//             }
//         }

//         .companies {
//             max-height: 560px;
//             overflow-y: auto;
//             display: flex;
//             flex-direction: column;
//             padding-right: 5px;
//             padding-bottom: 24px;

//             .company_item {
//                 width: 100%;
//                 height: 160px;
//                 border: 1px solid $gray-dark2;
//                 border-radius: 20px;
//                 padding: 16px;
//                 margin-top: 24px;
//                 display: flex;
//                 align-items: center;
//                 position: relative;

//                 &.active {
//                     border-color: $green;
//                 }

//                 .info_section {
//                     margin-left: 24px;

//                     .company_name {
//                         font-family: $ff-ssp;
//                         font-style: normal;
//                         font-weight: 600;
//                         font-size: 30px;
//                         color: $dark;
//                     }

//                     .company_info_item {
//                         font-family: $ff-ssp;
//                         font-style: normal;
//                         font-weight: normal;
//                         font-size: 14px;
//                         color: $dark;

//                         p {
//                             font-family: $ff-ssp;
//                             display: inline;
//                             font-size: 16px;
//                             font-weight: 600;
//                             margin-left: 1px;
//                         }
//                     }
//                 }

//                 .action_buttons {
//                     position: absolute;
//                     right: 24px;
//                     top: 24px;
//                     display: flex;

//                     img {
//                         cursor: pointer;
//                     }

//                     .edit_company_icon {
//                         -webkit-filter: invert(1);
//                         -ms-filter: invert(1);
//                         filter: invert(1);
//                         margin-right: 10px;
//                     }
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 1550px) {
//     .profile_content {
//         flex-wrap: wrap;

//         .profile_content_item {
//             &:first-child,
//             &:nth-child(2) {
//                 width: 48%;
//             }

//             &:nth-child(3) {
//                 width: 100%;
//                 margin-top: 24px;
//             }
//         }
//     }
// }

// @media (max-width: 768px) {
//     .profile_content {
//         flex-wrap: wrap;

//         .profile_content_item {
//             width: 100% !important;

//             &:first-child {
//                 margin-bottom: 24px;
//             }

//             &:nth-child(3) {
//                 .companies {
//                     .company_item {
//                         padding: 10px;

//                         .img_section {
//                             width: 135px;
//                         }

//                         .info_section {
//                             margin-left: 15px;

//                             .company_name {
//                                 font-size: 20px;
//                             }

//                             .company_info_item {
//                                 font-size: 13px;

//                                 p {
//                                     margin-left: 2px;
//                                     font-size: 15px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// @media (max-width: 480px) {
//     .profile_content .profile_content_item {
//         padding: 10px;

//         &:nth-child(3) {
//             .companies .company_item .img_section {
//                 display: none;
//             }
//         }
//     }
// }
