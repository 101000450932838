@import "../../../scss/components/variables.scss";

.createArticle {
  margin: 24px;

  &__form {
    max-width: 800px;
  }

  &__fieldMessage {
    cursor: default;
    & > :nth-child(1) {
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
    font-family: $ff-ssp;
    font-size: 17px;
    color: $dark;
    font-weight: 600;
    margin: 24px 0 5px;
    opacity: 0.8;
  }

  &__fieldDescription {
    font-family: $ff-ssp;
    font-size: 14px;
    color: $gray-darker;
    font-weight: 300;
  }

  &__submitBtn {
    font-family: $ff-pf-light;
    margin: 30px 0;
    padding: 0 35px;
    @extend .green-btn;
  }
}
