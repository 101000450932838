.Event {
  padding: 24px;
  max-width: 50%;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input {
    background-color: #f5f6fa;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 14px 16px;

    &:focus {
      border-color: var(--color-green);
    }
  }

  &__textarea {
    height: 100px;
    resize: none;
  }

  &__submitBtn {
    width: fit-content;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  .Event {
    max-width: 100%;
  }
}
