.BodyBold {
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  font-family: var(--font-ssp);
  font-style: normal;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BodyBold {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .BodyBold {
    font-size: 14px;
  }
}
