.modal {
  max-width: 520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border-radius: 12px;

  &__top {
    padding: 18px 0;
    border-bottom: 1px solid var(--color-gray);
  }

  &__companies {
    --gap: 12px;

    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(50% - var(--gap)), 1fr)
    );
    gap: var(--gap);
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding: 12px;
  }

  &__continueBtn {
    min-width: 160px;
    margin-right: auto;
    margin-top: 20px;
  }
}

.Company {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 4px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(223, 227, 243, 0.7);
  border: 1px solid transparent;
  border-radius: 12px;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: var(--color-green);
  }

  &__selected {
    border-color: var(--color-green);
  }

  &__logo {
    max-width: 120px;
    width: 100%;
    max-height: 65px;
    height: 100%;
    object-fit: contain;
  }

  &__name {
    text-align: center;
  }
}
