.StudyCenter {
  padding: 32px 24px;

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__rbpLink {
    color: var(--color-green);
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__categories {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
