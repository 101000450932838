.Materials {
  padding: 32px 24px;

  &__tabs {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }

  &__createBtn {
    margin-bottom: 16px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    row-gap: 16px;
    column-gap: 16px;
  }
}

.inHidden {
}

@media screen and (max-width: 768px) {
  .Materials {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
