.companyCard {
  border-radius: 10px;
  box-shadow: 0px 5px 5px 5px #f2f2f2d0;
  margin: 10px;
  padding: 14px;
  display: flex;
  justify-content: space-between;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    max-width: 200px;
    min-width: 200px;

    img {
      width: 100%;
      height: 130px;
      object-fit: contain;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    margin: 0 20px;
  }

  &__detail {
    width: 100%;
    margin-right: 20px;
  }

  &__actions {
    display: flex;
  }
}
