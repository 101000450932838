@import "../../../scss/components/variables.scss";

.department_title {
  font-size: 20px;
  font-family: $ff-ssp;
  margin-bottom: 15px;
}

.department_top_panel {
  padding-top: 25px;
  width: max-content;
  display: inline-block;
  @extend .standard-content-padding;

  .department_title {
    font-size: 20px;
    font-family: $ff-ssp;
    margin-bottom: 15px;
  }

  .department_type_btn {
    height: 36px;
    padding: 0 20px;
    font-family: $ff-pf-light;
    font-size: 15px;
    font-weight: 300;
    margin-right: 10px;
    border: 1px solid var(--color-green);
    @extend .colorful-btn;

    &.active {
      background-color: $green;
      color: #fff;
    }
  }
}

// @import "../../components/variables";

// .backButton {
//   margin-left: 24px;
//   margin-top: 24px;
// }

//

.creation_btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  float: right;
  margin: 24px 14px;
}

@media (max-width: 768px) {
  .department_top_panel {
    width: auto;

    button {
      margin-bottom: 15px;
    }
  }

  .creation_btn {
    float: none;
    margin-top: 10px;
  }
}
