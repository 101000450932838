.table_row {
  &_center {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    min-height: 65px;
  }
  &_title {
    min-width: 150px;
    width: 100%;
    margin-right: 20px;
  }
}

.edit_and_save {
  display: flex;
}

.delete_btn {
  cursor: pointer;
}
