.table_row {
  &_edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    cursor: pointer;
    position: absolute;
    right: 30px;
  }
  &_center {
    position: relative;
    display: flex;
    font-size: 13px;
    text-align: left;
    line-height: 16px;
    min-width: 40px;
    min-height: 40px;
    align-items: center;
    height: 65px;
  }
  &_title {
    min-width: 340px;
    margin-right: 30px;
  }
  &_input {
    width: 100%;
    position: absolute;
    top: 20px;
    height: 30px;
  }
  &_save {
    height: 25px;
    position: absolute;
    top: 10px;
    cursor: pointer;
    border-radius: 9999px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_deleteBtn {
    all: unset;
    cursor: pointer;
  }
}

.edit_and_save {
  display: flex;
}
