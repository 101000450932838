@import "../components/variables";

.StudyCenter {
  padding: 24px;
  width: calc(100% - 240px);
  height: 100%;
  overflow-y: auto;

  &__panel {
    margin-top: 32px;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px 0px #dfe3f3b2;
    border-radius: 12px;
  }
}

.main {
  width: calc(100% - 240px);
  background-image: linear-gradient(180deg, #ffffff90, rgba(0, 0, 0, 0.2)),
    url(./lerning_develop.jpg);
  background-repeat: no-repeat;
  height: calc(100vh - 80px);
  background-size: cover;
}
.main_develop {
  color: #024bbb;
  padding: 0px 24px;
}

.content_head {
  margin-left: 22px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.page {
  margin: 25px auto;
  max-width: 1200px;
  &_head {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
    margin: 0 50px;
    border: #024ddd solid 2px;
    display: flex;
    text-align: center;
    flex-direction: column;
    font-size: 27px;
    border-radius: 20px;
  }
  &_content {
    display: flex;
    font-size: 18px;
  }
  &_left {
    background-color: rgba(255, 255, 255, 0.7);
    width: 50%;
    margin: 20px;
    min-height: 340px;
    border: #024ddd solid 2px;
    padding: 50px 20px;
    border-radius: 20px;
  }
  &_right {
    background-color: rgba(255, 255, 255, 0.7);
    width: 50%;
    height: 100%;
    margin: 20px;
    min-height: 340px;
    border: #024ddd solid 2px;
    padding: 50px 20px;
    border-radius: 20px;
  }
}

@media (max-width: 1024px) {
  .StudyCenter {
    width: 100%;
  }

  // MAIN BLOCKS
  .main {
    width: 100%;
  }
}

/// ________________ STUDY ________________

.list {
  display: flex;
  & :nth-child(1) {
    margin-right: 10px;
  }
}
.background_img {
  min-height: 100vh;
}

.study_content {
  font-family: $ff-ssp;
  margin-top: 15px;

  .study_content__promo {
    border-radius: 10px;
    width: 800px;
    max-width: 100%;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);

    img {
      transform: scale(1.08);
    }
  }
  // <img src="/img/static/lerning_develop.jpg" alt="" />

  .download_doc_section {
    width: max-content;
    padding: 16px 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    margin-top: 24px;

    .download_doc_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 25px;
      margin-top: 15px;

      svg {
        min-width: 20px;
        min-height: 20px;
        padding-left: 5px;
      }
    }
  }
}

.categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 14px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 3px;

  .tag_item {
    color: $gray-darker;
    cursor: pointer;
    margin-right: 12px;
  }
}

.course_items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.course_item {
  width: 24%;
  height: 460px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0.5% 25px;
  position: relative;

  .course_edit_btn,
  .course_delete_btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #fff;
    color: $dark;
    padding: 10px;
    border-radius: 50%;
    z-index: 3;
  }

  .course_edit_btn {
    right: 65px;
  }

  .img_section {
    width: 100%;
    height: 250px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
    }

    &.with_label {
      .course_label {
        position: absolute;
        bottom: 18px;
        left: 22px;
        color: #fff;
        background: $green;
        display: flex;
        align-items: center;
        border: none;
        @extend .small-colorful-btn, .shadow-md;
      }
    }
  }

  .info_section {
    width: 100%;
    height: 200px;
    padding: 0 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    .course_title {
      font-family: $ff-ssp;
      font-weight: 600;
      font-size: 18px;
      color: $dark;
      margin-bottom: 5px;
    }

    .course_date {
      color: $gray-darker;
      font-size: 14px;
    }

    .teachers {
      font-weight: 600;
      font-size: 16px;
      color: $dark;
      margin-top: 6px;
      margin-bottom: 6px;

      span {
        font-weight: 500;
      }
    }

    .course_desc {
      font-size: 16px;
      color: $dark;
    }
  }
}

@media (max-width: 1800px) {
  .course_item {
    width: 32%;
  }
}

@media (max-width: 1350px) {
  .course_item {
    width: 49%;
  }
}

@media (max-width: 768px) {
  .course_item {
    .info_section {
      padding: 0 10px;
    }
  }
}

@media (max-width: 480px) {
  .categories,
  .tags {
    padding: 0 10px;
  }

  .course_item {
    width: 100%;
    margin: 0 0 25px;

    .tags {
      padding: 0;
    }
  }
}
