.modal {
  max-width: 500px;
  width: 95%;
  border-radius: 12px;
  &__head {
    width: 100%;
  }
  &__line {
    margin-top: 23px;
    background-color: #eff1f8;
    height: 1px;
    width: calc(100% + 40px);
  }
  &__request {
    margin-top: 27px;
    width: 100%;
    height: 100%;
    display: flex;
  }
  &__text {
    height: 100%;
    min-height: 70px;
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    & > :nth-child(1n) {
      margin-bottom: 4px;
    }
  }
  &__check {
    margin-top: -7px;
    cursor: pointer;
  }
  &__edit {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__file {
    cursor: pointer;
    margin-top: 24px;
    padding: 0 14px;
    border-radius: 12px;
    background-color: #f5f6fa;
    width: 100%;
    height: 44px;
    min-height: 44px;
    display: flex;
    align-items: center;
    & > :nth-child(1) {
      margin-right: 10px;
    }
    & > :nth-child(2) {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    width: 100%;
    margin-top: 24px;
    & > :nth-child(1) {
      margin-right: 20px;
    }
    & > :nth-child(1n) {
      width: 164px;
      height: 40px;
    }
  }
  &__message {
    margin-top: 24px;
    height: 100%;
    & > :nth-child(1) {
      margin-bottom: 24px;
    }
  }

  &__error {
    margin-top: 12px;
  }

  &__fileInput {
    margin-top: 12px;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__fileInputBtn {
    white-space: nowrap;
  }
}
