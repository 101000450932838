@import "../../components/variables";

.confirm_modal {
  max-width: 100%;
  border-radius: 12px;
  min-height: 200px;

  &__btn {
    height: 32px;
  }
}

.confirm_modal_text {
  text-align: center;
  margin-top: 1rem;
  padding: 0 1px;
}

.exclamation_icon {
  width: 24px;
  height: 24px;
  margin: 24px auto 15px;
  color: #6f8bc4;
}

.typeMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 50px;
  font-size: 30px;
}

.exclamation_smile {
  cursor: default;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  & > :nth-child(1n) {
    margin-right: 10px;
  }
}
