.table_row {
  &_mini {
    width: fit-content;
    max-width: 120px;
  }
  &_center {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    min-height: 40px;
    min-height: 65px;
    margin-left: -20px;
  }

  &_editIcon {
    cursor: pointer;
  }

  &_title {
    min-width: 150px;
    width: 100%;
  }
}
