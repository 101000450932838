@import "../../scss/components/variables";

.container {
  width: 350px;
  height: 40px;
  position: relative;
  background-color: $gray-dark;
  display: flex;
  input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 0 12px;
    width: 100%;
  }
}

.search_icon {
  cursor: pointer;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gray {
  background-color: $gray-dark;
}
.white {
  background-color: $white;
}
