.Card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(199, 205, 230, 0.7);
  border-radius: 12px;
  max-width: 300px;
  width: 100%;
  cursor: pointer;

  &__imageWrapper {
    width: 100%;
    height: 225px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }

  &__content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__postlinkBtn {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
