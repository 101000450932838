@import "../../components/variables";

.kanban {
  width: 100%;
  height: 100%;
  max-height: 700px;
  @extend .flex;
  align-items: stretch;
  &_column {
    flex: 1 0 25%;
    padding: 16px 6px;
    overflow-y: scroll;
    &:not(:nth-last-of-type(1)) {
      border-right: 1px solid $gray-200;
    }
    &_header {
      width: 100%;
      height: 40px;
      -webkit-clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
      clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
      background-color: rgb(43, 84, 207);
      border-radius: 3px;
      margin-bottom: 16px;
      color: $white;
      position: relative;
      @extend .flex-n-c, .px-12, .font-semibold;
      .plus_icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
    &_card {
      width: 100%;
      min-height: 200px;
      background-color: $gray-dark;
      border-radius: 4px;
      box-shadow: 0px 0px 4px rgba(223, 227, 243, 0.7);
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      @extend .tiny-content-padding, .hover-animation-card;
    }
    &_title {
      @extend .font-semibold;
    }
    &_date {
      border-radius: 16px;
      height: 32px;
      background-color: $blue-pastel;
      color: $white;
      width: fit-content;
      margin: 16px 0 26px 0;
      @extend .tiny-content-padding, .flex-c-c;
    }
    &_section {
      flex: 1;
      span {
        @extend .font-semibold;
      }
    }
    &_stats {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
      }
      svg {
        margin-right: 5px;
        pointer-events: none;
      }
      span {
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}
.bg {
  &__blue {
    background-color: $blue;
  }
  &__s1 {
    background-color: $blue;
  }
  &__blue-light {
    background-color: $blue-light;
  }
  &__s2 {
    background-color: $blue-light;
  }
  &__green {
    background-color: $green;
  }
  &__s3 {
    background-color: $green;
  }
  &__purple {
    background-color: $purple;
  }
  &__s4 {
    background-color: $purple;
  }
  &__red {
    background-color: var(--color-red-row);
  }
}
