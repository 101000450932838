.ADD {
  background-color: var(--color-green-row);
}

.EDIT {
  background-color: var(--color-yellow-row);
}

.DELETE {
  background-color: var(--color-red-row);
}

.table_row {
  &_edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    cursor: pointer;
    position: absolute;
    left: 20px;
  }
  &_center {
    position: relative;
    display: flex;
    font-size: 13px;
    text-align: left;
    line-height: 16px;
    min-width: 40px;
    min-height: 40px;
    height: 75px;
  }
  &_vertical {
    align-items: center;
  }
  &_title {
    width: 340px;
    overflow: hidden;
    overflow-y: auto;
    word-wrap: break-word;
  }
  &_input {
    width: 100%;
    position: absolute;
    top: 20px;
    height: 30px;
  }
  &_save {
    height: 25px;
    position: absolute;
    top: 20px;
    cursor: pointer;
    border-radius: 9999px;
  }
}
