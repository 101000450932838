.modal {
  &__title {
    background-color: #ffffff;
    padding: 10px 30px;
  }

  &__tableHead {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
  }

  &__categoryTitle {
    display: flex;
    cursor: pointer;
  }

  &__arrowIcon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_open {
      transform: rotate(180deg);
    }
  }
}
