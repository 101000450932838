@import "../../components/variables";

.creation_content_container {
  width: 800px;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  @extend .standard-content-padding;
}

.title_panel {
  font-family: $ff-ssp;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $dark;
  margin-bottom: 20px;
}

.dropzone {
  margin: 14px 0;
}

.submit_btn {
  height: 40px;
  padding: 0 40px;
  margin-top: 24px;
  margin-right: 24px;
  @extend .green-colorful-btn;
}

.executor_text {
  font-family: $ff-ssp;
  color: $dark;
  font-size: 16px;
  margin-top: 15px;
  padding-left: 5px;
}

.form_files_list {
  width: 100%;

  .form_file_item {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
    background: $gray-dark;
    border-radius: 10px;
    font-family: $ff-ssp;
    font-size: 14px;
    color: $dark;

    img {
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }

    svg {
      width: 17px;
      height: 17px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.7);
      margin-left: 9px;
    }
  }
}
