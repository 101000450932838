@import "../components/variables";

.row {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
}

.styleInput {
  width: 100%;
  min-width: 360px;
}

.buttons {
  display: flex;
  align-items: center;
  font-weight: wrap;
  & > :nth-child(1n) {
    margin-bottom: 30px;
    margin-right: 20px;
  }
}
.content {
  max-width: 600px;
  .block {
    margin: 30px 0;
    &_title {
      font-weight: 600;
      font-size: 18px;
    }
    &_content {
      display: flex;
      gap: 2rem;
      .colors {
        padding: 15px 0;
        flex-basis: 60%;
        .color {
          margin-bottom: 20px;
          p {
            margin-bottom: 8px;
          }
        }
      }
      .demo {
        margin: 15px 0;
        padding: 45px 25px 15px 25px;
        width: 250px;
        border-radius: 6px;
        border-width: 4px;
        border-style: solid;
        position: relative;
        .flag {
          position: absolute;
          left: 20px;
          top: 0px;
          width: 42px;
          height: 35px;
          clip-path: polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%);
        }
        &_title {
          position: absolute;
          bottom: calc(100% + 15px);
          left: 50%;
          transform: translateX(-50%);
          color: $gray-darker2;
        }
      }
      .functional {
        padding: 15px 0;
        width: 100%;
        &_item {
          display: flex;
          align-items: center;
          width: 100%;
          .title {
            display: flex;
            align-items: center;
            flex-grow: 1;
            gap: 1rem;
            min-height: 50px;
            padding: 10px 0;
            > * {
              cursor: pointer;
            }
            input {
              background-color: $gray-dark;
              padding: 5px;
              width: max-content;
            }
          }
          .delete {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.colorPicker {
  border-radius: 3px;
  overflow: hidden;
}
.selectTariff {
  margin-left: 20px;
  color: $green;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  width: min-content;
  overflow: visible;
  padding: 0 25px 0 0;
  position: relative;
  border-bottom: 1px dashed $green;
  outline: 0;
  option {
    padding: 5px;
    outline: 0;
  }
}
.arrow {
  pointer-events: none;
  transform: translateX(-15px);
}
