@import "../variables";

.sending_for_signature_table {
  min-width: unset;

  .actions_col {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      height: 36px;
      margin-right: 10px;
      white-space: nowrap;
      padding: 0 20px;
      @extend .colorful-btn;
    }

    img {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.checkedList {
  margin-left: -10px;
  & > :nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 580px;
  }
}

.tableHead {
  margin-left: -10px;
}
