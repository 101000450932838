@import "../../components/variables";

.send_prescription_modal {
  min-width: 500px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// TODO: remove this unused selector
.executors_list {
  width: 650px;
  max-width: 100%;
  max-height: 500px;
  overflow-y: auto;

  @media (max-height: 800px) {
    max-height: 350px;
  }

  @media (max-height: 620px) {
    max-height: 275px;
  }

  .executor_row_item {
    width: 650px;
    max-width: 100%;
    height: 40px;
    font-family: $ff-ssp;
    color: $dark;
    font-size: 17px;
    border-top: 1px solid $gray-dark;
    border-bottom: 1px solid $gray-dark;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.close_button {
  display: block;
  margin: 24px auto 0;
}
