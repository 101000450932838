.archive {
  &__backBtn {
    margin-top: 12px;
  }

  &__table {
    border-radius: 15px;
    margin-top: 30px;
    padding: 14px 0;
  }
}
