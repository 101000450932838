@import "../../components/variables";

// SEND EVENT EMAIL MODAL
#event_email_modal {
  width: 600px;
  border-radius: 25px;
  padding: 50px 0;

  .content {
    height: 100%;

    .modal_form {
      width: 500px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      .send {
        width: max-content;
        padding: 5px 20px;
        border: 1px solid $green;
        border-radius: 25px;
        transition: 250ms;
        margin-top: 25px;

        &:hover {
          background: $green;
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.createTaskModal {
  width: 700px;
  height: 800px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  button {
    &:focus {
      outline: none !important;
    }
  }

  &__tabs {
    width: fit-content;
    margin: 24px;
    margin-bottom: 0;
  }

  &__mainTab {
    display: flex;
    flex-direction: column;
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__tab {
    flex: 1;
  }

  &__list {
    overflow-y: auto;
    max-height: 500px;
  }

  &__fileName {
    max-width: 50vw;
    overflow-wrap: break-word;
  }

  &__filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__search {
    margin-top: 24px;
    height: 53px;
  }

  &__filesSearch {
    width: 300px;
  }

  &__employeesSearch {
    width: 200px;
  }

  &__disabledCheckbox {
    opacity: 0.2;
  }

  &__submitBtn {
    align-self: flex-start;
  }
}

.list_title__signed {
  color: $green;
}
.list_title__unsigned {
  color: $red;
}

.main_pickers_container {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  & > :nth-child(1) {
    padding-right: 15px;
  }
  & > :nth-child(2) {
    padding-left: 15px;
  }
}

.pickers_container {
  display: flex;
  justify-content: space-between;
}

.pickers_input {
  overflow-y: hidden !important;
  overflow-x: auto !important;
  height: 50px;

  input {
    overflow-y: hidden !important;
    padding: 10px 0px 10px 10px !important;
  }

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}

// .selectTypes {
//   height: 150px;
//   overflow-y: scroll;
//   border: red solid;
// }

.border {
}
