.forbiddenWarningModal {
  width: 300px;

  @media screen and (max-width: 600px) {
    width: 80vw;
  }

  &__title {
    margin-top: 20px;
    font-size: 28px;
    line-height: 32px;
    font-weight: semibold;
    text-align: center;
    width: 100%;
  }

  &__btn {
    margin-top: 20px;
    width: 100%;
  }
}
