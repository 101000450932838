@import "../../scss/components/variables.scss";

.calendarDate {
  display: flex;
  &_date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
  }
  &_clear {
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
  }
}

.pickers_input {
  overflow: hidden !important;
  width: 206px;
  max-height: 55px;
  border-radius: 4px;
  background-color: $gray-dark !important;
  & > :nth-child(1) {
    padding-top: 7px;
    margin-left: 10px;
  }
  label {
    width: 226px;
  }
  input {
    margin-top: 7px;
    padding-left: 7px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    margin-top: -10px;
    transform: scale(0.8);
  }
}

.modal {
  z-index: 1000;
}
.modal_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-width: fit-content;
  width: 600px;
  min-width: 600px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 4px;
}
.calendar {
  height: 100%;
  max-height: 330px;
  &_yearPickerControlActive {
    background-color: #0da00d;
    border-radius: 99999px;
    color: black;
    &:hover {
      background-color: #0da00d;
      color: black;
    }
  }
  &_monthPickerControlActive {
    background-color: #0da00d;
    border-radius: 99999px;
    color: black;
    &:hover {
      color: black;
      background-color: #0da00d;
    }
  }
  &_weekday {
    color: #aaa;
    font-size: 12px;
  }
  &_day {
    color: black;
    &[data-weekend] {
      // color: black;
    }
    &[data-selected] {
      color: black;
      background-color: #0da00d;
      border-radius: 100px;
      position: relative;
    }
    &[data-in-range] {
      color: black;
      background-color: rgba(0, 128, 0, 0.279);
    }
    &[data-first-in-range] {
      color: black;
      background-color: #0da00d;
      position: relative;
      border-radius: 100px;
      &::after {
        content: "";
        background-color: rgba(0, 128, 0, 0.279);
        position: absolute;
        right: 0;
        left: 20px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &[data-last-in-range] {
      color: black;
      background-color: #0da00d;
      border-radius: 100px;
      position: relative;
      &::after {
        content: "";
        background-color: rgba(0, 128, 0, 0.279);
        position: absolute;
        left: 0;
        right: 20px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
  &_buttons {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #00b856;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    & > :nth-child(1) {
      width: 97px;
      height: 36px;
    }
    & > :nth-child(2) {
      margin-left: 6px;
      width: 64px;
      height: 36px;
    }
  }
}
