.TextPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 300px;
}

.WrapperImage {
  width: 800px;
}

.WrapperText {
  width: 800px;
  color: var(--color-dark);
}

.Title {
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 8px;
}

.Time {
  color: var(--color-gray-darker);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  word-spacing: 5px;
}

.Tag {
  display: flex;
  flex-wrap: wrap;
  color: var(--color-gray-darker);
  margin-bottom: 6px;

  .TagItem {
    margin-right: 8px;
  }
}

.Text {
  margin-top: 30px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;

  a {
    color: #85a884;
  }
}
