.factor {
  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.ADD {
  background-color: var(--color-green-row);
}

.EDIT {
  background-color: var(--color-yellow-row);
}

.DELETE {
  background-color: var(--color-red-row);
}
