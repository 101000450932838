.wrapper_article {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.header_block {
  display: flex;
  justify-content: space-between;
}

.Article {
  padding: 24px;
  display: grid;
  grid-template-columns: 25% 1fr 25%;
  gap: 24px;

  &__backBtn {
    cursor: pointer;
  }

  &__adminControls,
  &__mobileAdminControls {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  &__mobileAdminControls {
    display: none;
  }

  &__adminControlsBtn {
    margin: 0 !important; // потому что класс 'colorful_action_btn' добавляет отступы зачем-то, поэтому я здесь их сбрасываю
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 28px;
  }

  &__applyBtn {
    white-space: nowrap;
  }

  &__image {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: top center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media screen and (max-width: 768px) {
  .Article {
    grid-template-columns: 1fr;

    &__leftSide {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__mobileAdminControls {
      display: flex;
    }

    &__adminControls {
      display: none;
    }

    &__controls {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
