.wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.count_file {
  width: 100%;
  margin-top: 10px;
}

.dropzone_container {
  width: 100%;
  height: 150px;
  max-width: 300px;
  display: flex;
  // border: 1px dashed var(--color-svg-blue);
  border-radius: 12px;
  box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.1);
  cursor: pointer;
  transition: border-color 250ms;
  text-align: center;
  font-size: 15px;
  &_full {
    max-width: none;
  }
  &:hover {
    border-color: #6e6e6e;
  }
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uploaded_text {
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
}

.files {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.error {
  border-color: var(--color-red);
}
