.content {
  position: relative;
  padding: 24px;
  margin-bottom: 200px;
  background-color: var(--color-white);

  &__head {
    margin-top: 10px;
    cursor: default;
  }
  &__filter {
    margin-top: 34px;
    display: flex;
    align-items: center;
  }

  &__table {
    &_empty {
      height: 100px;
      display: grid;
      place-items: center;
    }
  }
}

.filter__button {
  width: 164px;
  height: 40px;
  margin-left: 20px;
}
