.Label {
  cursor: default;
  & > :nth-child(1) {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  font-size: 17px;
  color: var(--color-dark);
  font-weight: 600;
  margin: 24px 0 5px;
  opacity: 0.8;
}

.Editor {
  &_error {
    border-color: var(--color-red);
  }
}
