.error_container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;

  .error_icon {
    width: 100px;
    height: 90px;
    margin: auto;
  }

  .error_text {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    color: #373737;
    margin-top: 15px;
  }

  .error_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
