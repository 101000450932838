.table {
  overflow-x: auto;

  &__headCell {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
  }

  &__lastDate {
    min-width: 180px;
  }

  &__companyName {
    min-width: 180px;
  }

  &__employeeName {
    min-width: 200px;
  }

  &__position {
    min-width: 150px;
  }

  &__department {
    min-width: 150px;
  }

  &__factor {
    min-width: 300px;
  }

  &__nextDate {
    min-width: 160px;
  }

  &__sortIcon {
    transition: transform 0.2s ease-in-out;

    &_rotated {
      transform: rotate(180deg);
    }
  }

  &__lastColumn {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__actionBtn {
    all: unset;
    cursor: pointer;
  }
}

.box {
  &_red {
    background-color: var(--color-red-row);
  }
  &_orange {
    background-color: var(--color-orange-row);
  }
  &_yellow {
    background-color: var(--color-yellow-row);
  }
  &_green {
    background-color: var(--color-green-row);
  }
}
