@import "../../components/variables";

// TABLE STYLES

.path_text {
  font-family: $ff-ssp;
  color: $dark;
  padding-top: 0;
  @extend .standard-content-padding;
}

.removeAndArchive {
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.workspace_table {
  position: relative;

  .directoryIconWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .file_icon {
    margin-right: 20px;
  }

  tbody {
    tr {
      td:first-child {
        label {
          cursor: pointer;
        }
      }
    }
  }
}

.workspaceAccessRequirement {
  position: absolute;
  background-color: #ffffffaa;
  inset: 0;
  z-index: 10;
  display: grid;
  place-items: center;

  &__inner {
    position: sticky;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__button {
    max-height: unset !important;
    height: 60px;
    font-size: 20px !important;
  }
}

.nameFile {
  overflow: hidden;
  width: 100%;
  margin-right: 40px;
  text-align: start;
}

.folder {
  width: unset !important; // потому что в глобальных base-table стилях ей ставится 350px
  display: block;
  .file_icon {
    margin-right: 20px;
  }

  label {
    cursor: default !important;
  }
}

.archiveTableHeader {
  &__actions {
    width: min-content;
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 12px;
  }
}

.archiveTable {
  tbody {
    tr {
      td:last-child {
        position: relative;
        width: min-content;

        .document_date {
          position: absolute;
          right: 24px;
          bottom: 21px;
          font-size: 17px;
          cursor: default;
        }

        .removeAndArchive {
          position: absolute;
          opacity: 0;
          right: 24px;
          bottom: 24px;
        }
      }

      &:hover {
        td:last-child {
          .document_date {
            opacity: 0;
          }

          .removeAndArchive {
            opacity: 1;
          }
        }
      }
    }
  }
}

.table_buttons {
  button {
    margin-left: 12px;
    opacity: 0.9;
    transition: opacity 250ms;

    &:hover {
      opacity: 1;
    }
  }
}

.signature_status_2 {
  color: $red;
}

// WORKSPACE PAGE STYLES

.workspace_top_panel {
  font-family: $ff-ssp;
  color: $dark;
  @extend .standard-content-padding;
}

.workspaceGroups {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 12px;
}

.workspaceGroup {
  &__editIcon {
    width: 35px;
    height: 22px;
    color: $gray-darker;
    cursor: pointer;
    transition: color 200ms;

    &_active {
      color: #ffffff;
    }
  }

  &_notAvailable {
    opacity: 0.3;
  }
}

// CREATION BUTTONS

.workspace_buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-top: 15px;
}

.selectedItemsActions {
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content;
}

@media (max-width: 450px) {
  .workspace_groups {
    .workspace_group_item {
      margin-right: 5px;

      .workspace_group_edit_btn {
        padding: 7px 10px 7px 12px;

        &.without_btn {
          padding: 7px 10px;
        }
      }

      .workspace_group_edit_icon {
        padding-right: 12px;
      }
    }
  }
}

.archiveTableTop {
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.documentTemplatesFileName,
.workspaceFileName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
