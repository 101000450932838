@import "../components/variables";

/* TAB CONTENT SECTIONS */
// TARIFF SECTION (FIRST TAB)

main.payment section.tab-content-item:first-child {
  @extend .standard-content-padding;
}

div.tariff {
  width: 265px;
  height: 120px;
  background: url($imgPath + "/tariff-bg.jpg");
  background-size: cover;
  overflow: hidden;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  @extend .rounded-xl;

  &.light-tariff {
    background-image: url($imgPath + "/light-tariff-bg.jpg");
  }

  &.choosing-tariff {
    height: 180px;
  }

  div.tariff-cont {
    padding: 0 10px;
  }
}

p.tariff-title {
  font-weight: 600;
  font-size: 18px;
}

p.tariff-subtitle {
  font-weight: 600;
  font-size: 16px;
}

p.tariff-desc {
  font-weight: normal;
  font-size: 14px;
}

div.tariffication-period {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 14px;
}

div.payment-method {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

// BUTTON || DIV || LINK
button.tariff-period-btn {
  height: 55px;
  padding: 0 40px;
  margin-right: 15px;
  margin-bottom: 10px;
  @extend .bordered-btn;
}

button.payment-method-btn {
  margin-right: 15px;
  @extend .small-bordered-btn;
}

div.payment-price {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 20px;

  p {
    margin-right: 10px;
  }

  span {
    font-size: 30px;
  }
}

// PAYMENT HISTORY SECTION (SECOND TAB)

// TARIFF TABLE

div.tariff-table-container {
  padding-top: 0;
  padding-bottom: 0;
  overflow-x: auto;
  @extend .standard-content-padding;
}

button.choose-tariff-btn {
  width: 100%;
  @extend .large-colorful-btn;
}

div.choosing-tariff {
  margin-bottom: 24px;

  div.tariff-cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
  }
}

table.tariff-table {
  width: max-content;
  font-family: $ff-ssp;
  font-weight: 600;
  font-size: 18px;
  color: $dark;

  tbody tr {
    &:nth-child(even) {
      background: $gray-dark;
    }
  }

  thead,
  tbody {
    tr {
      width: 100%;

      td {
        width: 265px;
        padding-right: 20px;

        img {
          margin: auto;
        }

        &:first-child {
          width: max-content;
          text-indent: 16px;
          padding-top: 12px;
          padding-bottom: 12px;
        }

        &:last-child {
          padding-right: 16px;
        }
      }
    }
  }
}

div.tariff-cards {
  padding: 3rem 3rem;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 3rem;
  font-weight: 600;
  .card {
    &__chosen {
      border-width: 8px;
      margin-top: -8px;
    }
    width: calc(100% / 3 - 2rem);
    border-radius: 20px;
    padding: 140px 30px 50px 30px;
    border: solid 3px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
      font-size: 28px;
    }
    .showMoreOrLess {
      text-align: center;
      color: $green;
      cursor: pointer;
      margin-top: 20px;
    }
    .modules {
      margin-top: 30px;
      font-size: 20px;
      width: 100%;
      li {
        display: flex;
        gap: 1rem;
        margin-bottom: 15px;
      }
    }
    button {
      width: 286px;
      height: 64px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      border: 1px solid $green;
      color: $green;
      margin-top: 30px;
      font-size: 20px;
      transition: 0.2s;
      &:hover {
        background-color: $green;
        color: white;
      }
      &.expiring {
        background-color: $red-light1;
        color: $white;
        border: none;
        &:hover {
          background-color: var(--color-red-row);
        }
      }
    }
    .card_price {
      margin: 30px 0;
      font-size: 25px;
    }
    .devider {
      margin-top: 10px;
      width: 100%;
      height: 1px;
      background-color: $gray-dark3;
    }
    .edit-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .flag {
      color: $white;
      font-size: 37px;
      position: absolute;
      padding-top: 20px;
      left: 60px;
      top: -2px;
      width: 110px;
      height: 110px;
      display: flex;
      justify-content: center;
      clip-path: polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%);
    }
    &:nth-child(1) {
      border-color: $blue-tarrif;
      .flag {
        background-color: $blue-tarrif;
      }
    }
    &:nth-child(2) {
      border-color: $green;
      // background-color: #b3e6b5;
      .flag {
        background-color: $green;
      }
    }
    &:nth-child(3) {
      border-color: $purple-tarrif;
      .flag {
        background-color: $purple-tarrif;
      }
    }
  }
}

@media (max-width: 1440px) {
  div.tariff-cards {
    .card {
      width: calc(100% / 2 - 2rem);
    }
  }
}

@media (max-width: 720px) {
  div.tariff-cards {
    display: flex;
    flex-direction: column;
    .card {
      width: 100%;
      max-width: unset;
    }
  }
}
