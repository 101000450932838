.fileName {
  width: 100%;
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formFeedback {
  max-width: 700px;
}

.isLoader {
  height: 300px;
}

.feedback_row {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-top: 10px;
  background: var(--color-gray-dark);
  border-radius: 10px;
  font-size: 14px;
  color: var(--color-dark);
  &_file {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    & > :nth-child(1) {
      margin-right: 20px;
    }
    & > :nth-child(2) {
      width: 100%;
      overflow: hidden;
    }
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }

  svg {
    width: 17px;
    height: 17px;
    cursor: pointer;
  }
}

.loadFile {
  height: 40px;
  width: 40px;
  position: relative;
}

.labelFile {
  cursor: pointer;
  border-radius: 99999px;
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--color-gray-dark);
  }
}

.btnAndClip {
  display: flex;
  height: 80px;
  align-items: center;
  & > :nth-child(1) {
    margin-right: 20px;
  }
}
