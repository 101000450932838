.wrapper {
  width: max-content;
}

.cursor {
  cursor: pointer;
}

.marginRight {
  margin-right: 14px;
}

// смотреть
.blue-dark {
  & > :nth-child(1n) {
    fill: var(--color-svg-blue-dark);
  }
}

.gray {
  & > :nth-child(1n) {
    fill: var(--color-svg-gray);
  }
}

// Календарь
.blue {
  & > :nth-child(1n) {
    fill: var(--color-svg-blue);
  }
}

//Изменить
.orange {
  & > :nth-child(1n) {
    fill: var(--color-svg-orange);
  }
}

.gray-dark {
  & > :nth-child(1n) {
    fill: var(--color-svg-gray-dark);
  }
}

// Архив
.red {
  & > :nth-child(1n) {
    fill: var(--color-svg-red);
  }
}

// Добавить документы
.green {
  & > :nth-child(1n) {
    fill: var(--color-svg-green);
  }
}

// Скачать | Вернуть
.green-light {
  & > :nth-child(1n) {
    fill: var(--color-svg-green-dark);
  }
}
