.BodyLight {
  display: inline-block;
  font-size: 14px;
  line-height: 15px;
  font-family: var(--font-ssp);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BodyLight {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .BodyLight {
    font-size: 10px;
    line-height: 12px;
  }
}
