.modal {
  width: 360px;
  border-radius: 12px;
  padding: 30px;

  &_buttons {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
  }
}
.table {
  margin-top: 24px;
  &_head {
    width: 20%;
    margin: 0;
    padding: 0 10px 0 0 !important;
    &_wrapper {
      max-width: 300px;
      display: flex;
      align-items: center;
      text-align: left;
      height: 50px;
      padding: 0 20px;
      cursor: default;
    }
    &_name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 20px;
    }
    &_sort {
      padding-left: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
