@import "../../../scss/components/variables.scss";
.checked_list {
  cursor: default;
  display: flex;
  align-items: center;
  background-color: $gray-dark;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -10px;
  margin-bottom: -10px;
  & > :nth-child(1) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.box_input {
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  line-height: 50px !important;
  height: 70px !important;
  margin-top: -10px !important;
  & > :nth-child(1) {
    padding-left: 0px !important;
    margin-top: -24px !important;
  }

  &_smile {
    font-weight: 400 !important;
    padding-left: 10px;
    color: black !important;
    width: 300px;
    font-size: 16px !important;
    background-color: #eff1f8 !important;
    opacity: 0;
    & :hover {
      background-color: #eff1f8 !important;
    }
    & :focus {
      background-color: #eff1f8 !important;
    }
  }
  &_big {
    font-weight: 600 !important;
    color: $green !important;
    width: fit-content;
    font-size: 30px !important;
    background-color: #ffffff !important;
    & :hover {
      background-color: #ffffff !important;
    }
    & :focus {
      background-color: #ffffff !important;
    }
  }
}

.container {
  background-color: #ffffff !important;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  padding-top: 3px;
  height: 48px;
  width: 300px;
  position: relative;
  &_big {
    height: 45px;
    width: fit-content;
    overflow: hidden;
    border-bottom: $green dashed 1px;
  }
}

.selectedValue {
  position: absolute;
  inset: 0;
  height: 50px;
  font-weight: 400;
  padding: 0 10px;
  color: black;
  font-size: 16px;
  background-color: #eff1f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  &__icon {
    transition: transform 0.3s ease;

    &_rotated {
      transform: rotate(180deg);
    }
  }

  &__text {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
