.wrapper {
  height: 58px;
  width: 100%;
}

.input {
  padding: 0 10px;
  background-color: var(--color-gray);
  width: 100%;
  display: inline-block;
  &[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    -moz-appearance: textfield;
  }
}

.font {
  &_large {
    height: 50px;
    padding: 4px 10px;
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
    font-family: var(--font-ssp);
  }
  &_medium {
    height: 22px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
    font-family: var(--font-ssp);
  }
  &_small {
  }
}
