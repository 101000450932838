.wrapper {
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    display: flex;
    align-items: center;
  }
}

.back {
  margin-left: 7px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 10px;
}

.add_programm {
  margin-top: 24px;
}
.modal {
  width: 360px;
  border-radius: 12px;
  padding: 30px;

  &_buttons {
    display: flex;
    margin-top: 30px;
    justify-content: space-around;
  }
}
