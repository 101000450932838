.content {
  min-height: calc(100vh - 600px);
  position: relative;
  padding: 24px;
  margin-bottom: 200px;
  background-color: var(--color-white);
  &__close {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}

.warning {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
