@import "../../../scss/components/variables.scss";
.checked_list {
  cursor: default;
  display: flex;
  align-items: center;
  position: relative;
  background-color: $gray-dark;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -10px;
  margin-bottom: -10px;

  &_row {
    & > :nth-child(1) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.block_color {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  &_red-row {
    background-color: var(--color-red-row);
  }
  &_orange-row {
    background-color: var(--color-orange-row);
  }
  &_yellow-row {
    background-color: var(--color-yellow-row);
  }
  &_green-row {
    background-color: var(--color-green-row);
  }
}

.box_input {
  overflow: hidden !important;
  width: 206px !important;
  background-color: $gray-dark !important;

  width: fit-content;
  font-weight: 500 !important;
  font-size: 14px !important;
  & :hover {
    background-color: $gray-dark !important;
  }
  & :focus {
    background-color: $gray-dark !important;
  }
}

.container {
  height: 55px;
}
