.message {
  box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.2);
  position: relative;
  width: fit-content;
  max-width: min(800px, 100%);
  margin-bottom: 24px;
  background-color: #e3e8f769;
  border-radius: 15px 15px 15px 0;
  padding: 12px 16px;
  padding-top: 14px;

  &_mine {
    background-color: #abfbca2a;
    border-radius: 15px 15px 0 15px;
  }

  &_withFiles {
    padding-left: 18px;
  }

  &__author {
    position: absolute;
    bottom: 100%;
    left: 24px;
    transform: translateY(-8px);
  }

  &__text {
    padding-right: 20px;
    overflow-wrap: break-word;
  }

  &__time {
    margin-top: 6px;
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &_mine {
      justify-content: flex-end;
    }
  }
}

.splitDate {
  background-color: #d5d5d52a;
  padding: 5px 20px;
  border-radius: 99999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  color: var(--color-gray-darker2);
  margin-bottom: 32px;
}
