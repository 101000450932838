.save {
  box-shadow: 18px -1px 18px 1px #eee;
  background-color: var(--color-white);
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 240px;
  padding: 24px;
}

.buttons {
  display: flex;
  margin-left: 30px;
  & > :nth-child(1n) {
    margin-right: 20px;
  }
}
.item_check {
  cursor: pointer;
  width: 265px;
  display: flex;
  align-items: center;
  height: fit-content;
  &_text {
    width: 200px;
  }
}
.edit {
  width: 35px;
  height: 35px;
  margin-left: 4px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal {
  width: 600px;
  border-radius: 12px;
  &_head {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
  &_content {
    margin-top: 15px;
    width: calc(100% + (4 * 9px));
    margin-left: calc(-2 * 9px);
    // border-top: 1px solid #eff1f8;
    // border-bottom: 1px solid #eff1f8;
    padding: 32px 20px;
  }
  &_button {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &_changes {
    width: fit-content;
    margin-top: 8px;
    display: flex;
    & > :nth-child(1) {
      width: fit-content;
    }
  }
  &_info {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  &_check {
    max-height: 190px;
    border-radius: 6px;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #eff1f8;
    &_head {
      margin-top: 0px;
    }
  }
  &_boxes {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 250px 250px;
    column-gap: 10px;
  }
}
