.options {
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: flex-end;
}

.cell {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: var(--color-green);
  }
}

.username {
  margin-left: 20px;
}

.filed {
  cursor: pointer;
  width: 500px;
  display: flex;
  align-items: center;
  & > :nth-child(1) {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.employee_control_col {
  display: flex;
  align-items: center;
  float: right;

  img {
    margin-left: 15px;
    cursor: pointer;
  }
}

.archive {
  margin-right: 10px;
  margin-left: 30px;
}
