.modal {
  width: 350px;
  padding: 20px 30px;
  display: block;
  &_head {
    margin-top: 10px;
    margin-bottom: 24px;
  }
  &_input {
    display: flex;
    width: 100%;
    flex-direction: column;
    & > :nth-child(1n) {
      margin-bottom: 24px;
    }
  }
  &_buttons {
    display: flex;
    justify-content: flex-end;
    & > :nth-child(1) {
      margin-right: 20px;
    }
  }
}
