.list {
}

.ListFeedBack {
  margin-top: 20px;
  margin-bottom: 10px;
  min-height: 960px;
}

.closedMessage {
  width: fit-content;
  min-width: fit-content;
  margin: 0 30px;
  color: var(--color-green);
  border: var(--color-green) solid;
  padding: 3px 10px;
  border-radius: 9999px;
  cursor: default;
}

.ListFeedBackItem {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  border-bottom: #fff solid 1px;
  transition: all 0.1s ease;
  border-radius: 10px;

  &:hover {
    border-bottom: #f1f1f1 solid 1px;
    color: var(--color-green);
    background-color: #f9f9f9;
  }

  > :first-child {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.supportCreatedAt {
  display: flex;
  justify-content: flex-end;
  color: var(--color-gray-darker2);
  margin-left: 20px;
  width: 220px;
  min-width: 220px;
  padding-right: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.header {
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 48px;
  cursor: default;

  &_tablet {
    column-gap: 24px;
  }

  &_mobile {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    column-gap: 0;
    row-gap: 4px;

    .header__search {
      max-width: none;
    }
  }

  &__top {
    width: 100%;
    column-gap: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__switch {
    flex-shrink: 0;
    margin-right: -16px;
  }

  &__search {
    width: 100%;
    max-width: 320px;
  }
}
