@import "../../components/variables";

.row {
  position: relative;
}
.options {
  margin-top: 17px;
  display: flex;
  align-items: center;
  min-width: 400px;
  justify-content: flex-end;

  &_statusAction {
    margin-right: 20px;
    white-space: nowrap;
  }

  &_buttons {
    & > * {
      white-space: nowrap;
    }
  }
}
.warning {
  cursor: default;
  color: var(--color-red);
  & > :nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > :nth-child(1) {
      margin-right: 10px;
    }
  }
}
.employee_table {
  .employee_control_col {
    display: flex;
    align-items: center;
    float: right;

    img {
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .importActions {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 20px;

    &__text {
      white-space: nowrap;
    }
  }

  tbody tr td:first-child label {
    cursor: pointer;
  }

  tr td {
    text-align: left;
  }
}

.selectedItemsActions {
  &__inner {
    display: flex;
    justify-content: flex-end;
  }
}

// PAGE STYLES

.employees_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}
.employees_top_panel {
  display: flex;
  align-items: center;
  column-gap: 12px;
  row-gap: 6px;
  flex-wrap: wrap;
  margin-top: 16px;
  @extend .standard-content-padding;

  &.second_top_panel {
    padding-top: 10px;
  }
}

.employees_type_btn {
  height: 36px;
  padding: 0 20px;
  font-family: $ff-pf-light;
  font-size: 15px;
  font-weight: 300;
  margin-right: 10px;
  @extend .colorful-btn;

  &.active {
    background-color: $green;
    color: #fff;
  }
}

.employeesMedicalExams {
  &__categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__table {
    width: 100%;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}
