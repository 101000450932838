@import "../../components/variables";
@import "../../components/fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.overflowYVisible {
  overflow-y: visible !important;
}

.create_company_modal {
  width: 650px;
  height: auto;

  #clients_select {
    padding: 15px 10px 10px;
  }

  section {
    margin: 24px 0 10px;
  }

  #clients_label {
    z-index: 2;
    padding-left: 10px;
  }
  .unlink {
    cursor: pointer;
  }
  .email {
    margin: 24px 0 12px 0;
    display: flex;
    span {
    }
    .yandex {
      height: 44px;
      border: 1px solid black;
      border-radius: 12px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        svg {
          margin-right: 12px;
        }
      }
      span {
        display: inline-block;
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 768px) {
  .create_company_modal {
    width: 100%;
  }
}
