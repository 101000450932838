@import "./variables.scss";

nav.menu {
  width: 235px;
  background-color: #fff;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

div.menu-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.menu-item {
  height: 64px;
  width: 100%;
  display: flex;
  color: $dark;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  background-color: #fff;
  cursor: pointer;
  transition:
    color,
    background-color 200ms;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 25px;
  &.active,
  &:hover {
    color: $green;
    background-color: #eff1f8;
    border-right: 2px solid $green;
  }

  &.notification:after {
    content: "";
    position: relative;
    width: 6px;
    height: 6px;
    background: $green;
    border-radius: 50%;
    right: -4px;
    top: -4px;
  }
  .attention {
    margin-left: auto;
    margin-right: 20px;
  }
}

div.menu-goskey {
  background: blue;
  font-size: 22px;
  color: #fff;
  &.active,
  &:hover {
    background: blue;
    color: #fff;
    border-right: 2px solid $green;
  }
}
.menu-item-icon {
  margin-right: 13px;
  width: 18px;
  height: 18px;
  object-fit: contain;
}
.menu-goskey-icon {
  margin-left: -4px;
  margin-right: 9px;
  max-width: 24px;
  object-fit: contain;
}
/* MEDIA QUERIES */

@media (max-width: 1024px) {
  nav.menu {
    position: absolute;
    left: -235px;
    bottom: 0;
  }
}
