@import "../../../scss/components/variables.scss";

.label {
  min-width: 206px;
  max-width: 206px;
}

.select {
  max-width: 206px;
  min-width: 206px;
  font-weight: 400 !important;
  font-size: 14px !important;
  background-color: $gray-dark !important;
}

.item {
  max-width: 500px;
  word-wrap: break-word; /* Перенос слов */
}
