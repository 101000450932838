@import "../components/variables";

.content {
  display: flex;
  padding: 40px;
  gap: 3rem;

  .roles {
    display: flex;
    flex-direction: column;
    > button {
      margin-bottom: 20px;
    }
    .level {
      padding-bottom: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
      border-bottom: 1px solid $gray-dark3;
    }
    .role {
      background-color: $gray-dark;
      padding: 8px 16px;
      border-radius: 4px;
      margin-bottom: 16px;
      text-align: left;
      &_selected {
        border-right: 3px solid $green;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .devider {
    width: 1px;
    display: flex;
    align-items: stretch;
    background-color: #e6e8ea;
  }
}

.container {
  background-color: $gray-dark;
}
.filters {
  height: 64px;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 0 0 12px 12px;
  background-color: $white;
  margin-bottom: 24px;
}
.table {
  background-color: $white;
  border: 12px 12px 0 0;
  overflow-x: scroll;
  overflow-y: visible;
  min-height: 55vh;
}
