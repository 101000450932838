.ImagePreview {
  width: 64px;
  height: 64px;
  border-radius: 4px;
  position: relative;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &__deleteBtn {
    position: absolute;
    inset: 0;
    background-color: #000000;
    opacity: 0.5;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}
