.wrapper {
  margin-bottom: 30px;
}

.contain_title {
  height: 58px;
  width: 100%;
}

.table {
  margin-top: 24px;
  &_head {
    margin: 0 10px;
    padding: 0 20px 0 0 !important;
    &_wrapper {
      text-transform: uppercase;
      font-family: "Source Sans Pro";
      font-size: 13px;
      display: flex;
      text-align: left;
      font-weight: 600;
      line-height: 16px;
      width: 100%;
      height: 70px;
      padding-right: 30px;
      cursor: pointer;
    }
    &_name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: fit-content;
    }
    &_sort {
      padding-left: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }

    &_sortIcon {
      transition: transform 0.2s ease-in-out;

      &_rotated {
        transform: rotate(180deg);
      }
    }
  }
}
