@import "../../components/variables";

.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -8px;
}

#event_email_modal {
  width: 600px;
  border-radius: 25px;
  padding: 50px 0;

  .content {
    height: 100%;

    .modal_form {
      width: 500px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      .send {
        width: max-content;
        padding: 5px 20px;
        border: 1px solid $green;
        border-radius: 25px;
        transition: 250ms;
        margin-top: 25px;

        &:hover {
          background: $green;
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.create_event_modal {
  button {
    &:focus {
      outline: none !important;
    }
  }
}

.main_pickers_container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  > :first-child {
    padding-right: 15px;
  }

  > :last-child {
    padding-left: 15px;
  }

  @media screen and (max-width: 960px) {
    > :last-child {
      padding-left: 0;
      padding-top: 15px;
    }
  }
}

.pickers_container {
  display: flex;
  justify-content: space-between;
  & > :nth-child(1) {
    max-width: 160px;
    min-width: 160px;
  }
  & > :nth-child(2) {
    margin-left: 20px;
    max-width: 160px;
    min-width: 160px;
  }
}

.pickers_input {
  overflow-y: hidden !important;
  overflow-x: auto !important;
  height: 50px;

  input {
    overflow-y: hidden !important;
    padding: 10px 0px 10px 10px !important;
  }

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}
