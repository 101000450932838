.content {
  margin-top: 20px;
  position: relative;
  padding: 24px;
  margin-bottom: 200px;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  &__filter {
    display: flex;
    align-items: center;
    & > :nth-child(1n) {
      margin-right: 10px;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__table {
    &_empty {
      height: 100px;
      display: grid;
      place-items: center;
    }
  }
}
