@import "../components/variables";

section.qa-tab-items {
  flex: 0 0 55%;
  height: auto;
  // margin-top: 30px;

  &.full-width {
    width: 100%;
  }
}

div.wrapper {
  display: flex;
  padding-top: 24px;
  gap: 56px;
}
div.qa-tab-items div.qa-tab__item {
  margin-bottom: 24px;
}

section.qa-tab-form {
  flex: 1 0 calc(45%-56px);
  background: #fff;
  border-radius: 15px;
  padding: 24px;
  height: fit-content;

  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    .file-label {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }

  .feedback_form_file_item {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
    background: $gray-dark;
    border-radius: 10px;
    font-family: $ff-ssp;
    font-size: 14px;
    color: $dark;

    .file_icon {
      margin-right: 10px;
    }

    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }

    svg {
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
  }
}
div.qa-tab__item {
  height: 70px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 25px;
}

p.qa__title {
  color: $dark;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  mix-blend-mode: normal;
}

div.qa-tab__trigger {
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 70px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  img.qa-tab__arrow {
    margin-right: 13px;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -webkit-transform: rotate(180deg);
    transform: none;
    transition: all 350ms;
  }
}

div.qa-tab__content {
  height: max-content;
  font-family: $ff-ssp;
  font-weight: normal;
  font-size: 16px;
  color: $dark;
  border-top: 1px solid $gray;
  line-height: 20px;
  @extend .standard-content-padding;

  &.feedback {
    padding-bottom: 0;
  }
}

div.qa-tab__item.active {
  height: auto;

  div.qa-tab__trigger {
    p.qa__title {
      color: $green;
    }

    img.qa-tab__arrow {
      filter: none;
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1280px) {
  .wrapper {
    flex-direction: column-reverse;
  }
  section.qa-tab-items {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  div.qa-tab__trigger {
    p.qa__title {
      font-size: 17px;
    }
  }
}
