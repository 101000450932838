.wrapper {
  display: flex;
  height: 100%;
}

.edit_title {
  width: 100%;
  max-width: 927px;
  height: 100%;
  padding: 4px 0;
}

.edit_type {
  margin-left: 20px;
  min-width: 400px;
  display: flex;
  align-items: center;
  & > :nth-child(1n) {
    margin-right: 30px;
    cursor: pointer;
  }
}

.line_path {
  background-color: #e6e8ea;
  height: 20px;
  width: 1px;
}

.edit_remove {
  display: flex;
  align-items: center;
  & > :nth-child(2) {
    margin-left: 10px;
  }
}

.modal {
  &_box {
    width: 360px;
    border-radius: 12px;
    min-height: 170px;
  }
  &_title {
    margin-top: 24px;
    height: 100%;
    text-align: center;
  }
  &_buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    & > :nth-child(1) {
      margin-right: 30px;
    }
  }
}
