@import "../../components/variables";
@import "../../components/variables.scss";

.onLinkDownload {
  padding: 5px 25px;
  margin: 5px 0;
  cursor: pointer;
  transition: 0.2s color;
  &:hover {
    color: var(--color-green);
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

// VIEW NEWS PAGE

.view_news_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 25px;

  .view_news_section {
    width: 48%;
  }

  .view_news_content_section {
    color: $dark;
    font-family: $ff-ssp;

    .view_news_title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 8px;
    }

    .view_news_gray_text {
      color: $gray-darker;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      word-spacing: 5px;
    }

    .view_news__tag_items {
      display: flex;
      flex-wrap: wrap;
      color: $gray-darker;
      margin-bottom: 6px;

      .tag_item {
        margin-right: 8px;
      }
    }

    .view_news_text {
      margin-top: 30px;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      white-space: pre-line;

      a {
        color: #85a884;
      }
    }
  }
}
.onloadFile {
  margin-top: 20px;
}
.additional_news {
  margin-top: 65px;

  .add_section_title {
    font-family: $ff-ssp;
    font-weight: 600;
    font-size: 18px;
    color: $dark;
    margin-left: 5px;
    margin-bottom: -10px;
  }
}

// NEWS CREATION PAGE

.create_news_container {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 0 24px 24px;
  margin-top: 30px;
  border-radius: 10px;

  form {
    width: 800px;

    .create_news_field_title {
      cursor: default;
      & > :nth-child(1) {
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
      font-family: $ff-ssp;
      font-size: 17px;
      color: $dark;
      font-weight: 600;
      margin: 24px 0 5px;
      opacity: 0.8;
    }

    .create_news_field_desc {
      font-family: $ff-ssp;
      font-size: 14px;
      color: $gray-darker;
      font-weight: 300;
    }

    .create_news_btn {
      font-family: $ff-pf-light;
      margin-top: 30px;
      padding: 0 35px;
    }
  }
}

// NEWS & PRACTICE PAGES

.tags {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-bottom: 3px;

  .tag_item {
    color: $gray-darker;
    cursor: pointer;
    margin-right: 12px;

    &.active {
      color: $green;
    }

    &:focus {
      outline: none;
    }
  }
}

.news_items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  font-family: $ff-ssp;
}

.news_item {
  width: 24%;
  height: 460px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0.5% 25px;
  cursor: pointer;
  border: 2px solid var(--news-item-color);

  .img_section {
    width: 100%;
    height: 250px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
    }
  }

  .info_section {
    width: 100%;
    height: 200px;
    padding: 14px 24px 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    .news_title {
      font-family: $ff-ssp;
      font-weight: 600;
      font-size: 18px;
      color: $dark;
      margin-bottom: 5px;
    }

    .news_date {
      color: $gray-darker;
      font-size: 14px;
    }

    .news_desc {
      font-size: 16px;
      color: $dark;
    }
  }
}

@media (max-width: 1800px) {
  .news_item {
    width: 32%;
  }
}

@media (max-width: 1350px) {
  .news_item {
    width: 49%;
  }
}

@media (max-width: 768px) {
  .news_item {
    .info_section {
      padding: 0 10px;
    }
  }
}

@media (max-width: 480px) {
  .categories,
  .tags {
    padding: 0 10px;
  }

  .news_item {
    width: 100%;
    margin: 0 0 25px;

    .tags {
      padding: 0;
    }
  }
}

@media (max-width: 1280px) {
  // VIEWING NEWS
  .view_news_container {
    flex-wrap: wrap;

    .view_news_section {
      width: 100%;

      &.view_news_slider_section {
        margin-bottom: 24px;
      }
    }
  }

  // NEWS CREATION
  .create_news_container {
    form {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .create_news_container {
    padding: 0 16px 16px;
  }
}

@media (max-width: 480px) {
  .create_news_container {
    padding: 0 10px 10px;
  }
}
