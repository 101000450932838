@font-face {
  font-family: "PF Centro Sans Pro Light";
  src:
    local("PF Centro Sans Pro Light"),
    local("PF-Centro-Sans-Pro-Light"),
    url("../../assets/fonts/PFCentroSansPro-Light.woff2") format("woff2"),
    url("../../assets/fonts/PFCentroSansPro-Light.woff") format("woff"),
    url("../../assets/fonts/PFCentroSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
