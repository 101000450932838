.fileList {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  background-color: #efefef44;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  backdrop-filter: blur(10px);
  padding: 12px;
  column-gap: 16px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
}

.file {
  box-shadow: 0px 1px 4px rgba(79, 79, 79, 0.2);
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  padding: 10px;
  background: #fafafa5f;
  border-radius: 10px;
  font-size: 14px;
  color: var(--color-dark);
  cursor: default;
  position: relative;

  &_disabled {
    opacity: 0.4;
  }

  > :nth-child(1) {
    margin-bottom: 5px;
    margin-top: 5px;
    width: 120px;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.closeFile {
  border-radius: 9999px;
  cursor: pointer;
  top: 0px;
  right: 0px;
  width: 25px;
  height: 25px;
  padding: 2px;
  position: absolute;
  transition: 0.3s ease all;
  background: var(--color-gray-dark);

  & :nth-child(1) {
    fill: var(--color-gray-darker2);
  }

  &:hover {
    background: var(--color-gray-dark3);

    & :nth-child(1) {
      fill: var(--color-green);
    }
  }
}
