.BodyNormal {
  display: inline-block;
  letter-spacing: 0em;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--font-ssp);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BodyNormal {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .BodyNormal {
    font-size: 14px;
  }
}
