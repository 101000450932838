@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import "./components/variables.scss";

/* SCROLLBAR STYLES */

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-button {
  background-color: var(--color-green);
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #999;
}

::-webkit-scrollbar-track-piece {
  background-color: var(--color-white);
}

::-webkit-scrollbar-thumb {
  height: 50px;
  border-radius: 3px;
  background-color: var(--color-green);
}

::-webkit-scrollbar-corner {
  background-color: #999;
}

::-webkit-resizer {
  background-color: #666;
}

*::selection {
  color: #f7f7f7;
  background: #262626;
}

/* CUSTOM STYLES*/

body,
#root {
  min-height: 100vh;
  overflow-x: hidden;
}

#root {
  max-width: 1920px;
  margin: auto;
}

.error-text {
  color: $red;
  font-weight: 400;
  font-size: 14px;

  &.large {
    font-size: 16px;
  }
  a {
    color: $dark;
    text-decoration: underline;
  }
}
.subtitle-text {
  color: $dark;
  font-weight: 400;
  font-size: 14px;

  &.large {
    font-size: 16px;
  }
}

.success-text {
  color: $green;
}

iframe {
  pointer-events: none;
}

.simplePDF_container iframe {
  pointer-events: all;
}

button.pagination-button {
  height: 36px;
  padding: 0 20px;
  margin: auto auto 20px;
  display: block;
  @extend .colorful-btn;
}

button.slick-active {
  background: #fff;
  border: 1px solid var(--color-green);
}

.slick_slider_arrow {
  position: absolute;
  top: calc(50% - 24px);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;

  &:focus {
    outline: none;
  }
}

.next_arrow {
  right: 10px;
}

.prev_arrow {
  left: 10px;
}

#sort-option-select {
  width: 200px;
}

/* COMMON CLASSES FOR MULTI USE */

.on_site_visit_loader {
  height: 100vh !important;
}

// UPLOADED FILES LIST
.form-files-list {
  width: 100%;

  .form-file-item {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
    background: $gray-dark;
    border-radius: 10px;
    font-family: $ff-ssp;
    font-size: 14px;
    color: $dark;

    .file-icon {
      margin-right: 10px;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }

    svg {
      width: 17px;
      height: 17px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.7);
      margin-left: 9px;
    }
  }
}

// FOR MODAL

.modal_title {
  padding: 24px 50px 24px 24px;
  font-family: $ff-ssp;
  color: $dark;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.close_modal_cross {
  width: 25px;
  height: 25px;
  top: 30px;
  right: 40px;
  z-index: 12;
  cursor: pointer;
}

.modal_content {
  padding: 0 24px 24px;
}

.modal_buttons_group {
  display: flex;
  justify-content: center;

  button:first-child {
    margin-right: 10px;
  }
}

.modal_btn {
  font-family: $ff-ssp;
  height: 38px;
  padding: 0 20px;
  margin-top: 24px;
  @extend .green-btn;

  &:disabled {
    background-color: $gray-dark2 !important;
    color: $gray-darker !important;
  }
}

.MuiButton-textPrimary {
  color: var(--color-green) !important;
}

.MuiPickersDay-daySelected,
.MuiPickersDay-root.Mui-selected,
.MuiPickersDay-root.Mui-selected:focus,
.MuiPickersDay-root.Mui-selected:hover {
  background-color: var(--color-green) !important;
}

.MuiPickersDay-today:not(.Mui-selected) {
  border: none !important;
  color: var(--color-green) !important;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--color-green) !important;
}

.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background-color: var(--color-green) !important;
}
.MuiTableCell-root {
  padding: 10px;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid var(--color-green) !important;
}

.MuiPickerDTTabs-tabs {
  background-color: var(--color-green) !important;
  border-top: 1px solid $gray;
}

@media (max-width: 768px) {
  .modal_content {
    padding: 0 14px 24px;
  }

  .react-responsive-modal-modal {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .modal_content {
    padding: 0 10px 24px;
  }

  .react-responsive-modal-modal {
    width: 95%;
    margin: 5px;
  }
}

@media (max-width: 370px) {
  .modal_buttons_group {
    button {
      padding: 0 30px;
    }
  }
}

// OTHER

div.underline {
  width: 100%;
  height: 1px;
  background-color: $gray;
}

div.content-wrapper {
  width: 100%;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  display: flex;
  position: relative;
  left: 0;
  transition: left 300ms;
  background-color: $gray;

  &.with-menu {
    left: 240px;
  }
}

.content-title-section-with-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.colorful_action_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-green);
  color: #ffffff;
  @extend .colorful-btn;

  &:not(.colorful_action_btn_noText) {
    padding: 0 20px;

    svg {
      margin-left: 5px;
    }
  }

  &_noText {
    width: 40px;
    justify-content: center;
  }

  svg {
    fill: #ffffff;
  }
}

.content-title {
  font-family: $ff-ssp;
  color: $dark;
  font-weight: 600;
  font-size: 30px;
  margin-top: 16px;
  display: flex;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }

  svg {
    margin-right: 10px;
  }
}

div.underline {
  width: 100%;
  height: 1px;
  background-color: $gray;
}

@media (max-width: 480px) {
  .content-title-section-with-btn {
    flex-wrap: wrap;
  }

  .content-title {
    margin-right: 10px;
    line-height: 30px;
  }
}

// CONTENT SECTION

main.content-container {
  width: calc(100% - 240px);
  height: 100%;
  background-color: #f4f6fa;
}

div.content-section {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 24px;
}

// BREADCRUMBS

div.bread-crumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
}

div.bread-crumb-item {
  font-family: $ff-ssp;
  color: $gray-darker;
  font-weight: normal;
  display: flex;
}

.back-arrow {
  width: 17px;
  color: $dark;
  display: inline;
  margin-right: 8px;
}

select {
  color: $gray-darker;
  font-family: $ff-ssp;
  background-image: url($imgPath + "/arrow_drop.png");
  background-repeat: no-repeat;
  background-position: 95%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px;

  &:focus {
    outline: none;
  }

  option {
    color: $dark;
  }
}

// TAB STYLES
div.tab-section {
  background-color: #fff;
  width: 100%;
  height: min-content;
  border-top-left-radius: 15px;
  -webkit-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  margin-top: 30px;
  font-family: $ff-ssp;
  font-size: 16px;
  color: $dark;
  font-weight: normal;
}

div.tab-cont {
  height: 100%;
}

div.tab-cont-short {
  height: 100%;
}

div.tab-items {
  width: 100%;
  height: min-content;
  font-family: "Source Sans Pro", sans-serif;
  border-bottom: 1px solid $gray;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-item {
  width: max-content;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  color: $gray-darker;
  font-weight: normal;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &.active {
    color: var(--dark);
    background-color: $gray;
    border-bottom: 2px solid $green;
  }
}

div.tab-content-items {
  height: max-content;
  background-color: #fff;
}

div.tab-content-item__top-panel {
  padding-bottom: 8px;
  @extend .standard-content-padding;
}

h4.tab-content-title {
  font-weight: 600;
  font-size: 30px;
  color: $dark;
  margin-right: 10px;
  margin-bottom: 5px;
  line-height: 40px;
}

@media (max-width: 480px) {
  //table.tab-table {
  //  tr {
  //    td {
  //      &:first-child {
  //        text-indent: 15px;
  //      }
  //
  //      &:nth-child(4) {
  //        padding-right: 15px;
  //      }
  //    }
  //  }
  //}

  .slick_slider_arrow {
    display: none;
  }
}

// TABLE TOP PANEL

div.table-top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 16px;
  @extend .standard-content-padding;

  &.without_padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  div.table-top-panel__widgets {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

div.search-panel {
  position: relative;
  width: 150px;
  margin-right: 100px;

  &.without_margin {
    margin-right: 0 !important;
  }

  input#search {
    width: 100%;
    height: 32px;
    font-family: $ff-ssp;
    font-size: 16px;
    color: $gray-darker;
    padding: 0 35px 0 10px;
    text-align: right;

    &:focus {
      color: $dark;
      text-align: left;
      outline-color: rgba(0, 0, 0, 0.5);
    }

    &:focus + img.search-icon {
      opacity: 1;
    }
  }

  img.search-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    opacity: 0.6;
  }
}

div.remains-free-space-wrapper {
  width: min-content;
  padding: 16px 24px;
  margin: 30px 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(223, 227, 243, 0.7);
  border-radius: 12px;
}

div.remains-free-space {
  white-space: nowrap;
  color: $gray-dark2;
  progress {
    width: 100%;
    height: 8px;
    border-radius: 4px;
  }
  progress::-webkit-progress-bar {
    background: $gray;
    border-radius: 4px;
    overflow: hidden;
  }
  progress::-webkit-progress-value {
    background-color: $green;
    border-radius: 4px;
  }
}

@media (max-width: 1024px) {
  // MAIN BLOCKS
  main.content-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  // MAIN BLOCKS
  div.content-section {
    padding: 0 15px;
  }

  // TABLE TOP PANEL
  div.search-panel {
    width: 100px;
    margin-right: 24px;
  }
}

@media (max-width: 480px) {
  // MAIN BLOCKS
  div.top-content {
    padding: 0 10px;
  }

  div.bread-crumbs {
    margin-top: 10px;
  }

  div.content-section {
    padding: 15px 5px 0;
    border-left: 1px solid $gray;
  }

  // TABLE TOP PANEL
  div.table-top-panel {
    div.table-top-panel__widgets {
      margin-top: 12px;
      width: 100%;
    }
  }

  div.search-panel {
    margin-right: 24px;
  }
}

// NOTE: this class is used on procedure-control pages to indicate which checkbox are disabled and which are not
// because material-ui doesn't provide default styles for disabled checkboxes
.mui_disabled_checkbox {
  opacity: 0.1;
}

// NOTE: remove this character \00a0 from mui TextField because it added overflow for some reason
.MuiFilledInput-underline::before {
  content: "" !important;
}
