@import "../../../scss/components/variables.scss";

.materialPage {
  margin-top: 24px;

  &__actionsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

.downloadFilesBtn {
  margin-top: 20px;
}

.materialFilesModal {
  max-width: 300px;
  width: 100%;

  &__filesList {
    margin-top: 20px;
  }
}
