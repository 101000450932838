.content {
  &__head {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.contain_title {
  height: 58px;
  width: 100%;
}

.table {
  margin-top: 24px;
  overflow-x: auto;

  &_head {
    &_wrapper {
      text-transform: uppercase;
      font-family: "Source Sans Pro";
      font-size: 13px;
      display: flex;
      text-align: left;
      font-weight: 600;
      line-height: 16px;
      width: 100%;
      height: 40px;
      cursor: default;
    }
    &_name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: fit-content;
      white-space: nowrap;
    }
    &_sort {
      padding-left: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }
  }
}

.add_employees_btn {
  margin-top: 12px;
}
