@import "../../components/variables.scss";

.row_color {
  background-color: var(--color-red-row);
}

.table {
  &_head {
    margin: 0;
    padding: 0 10px 0 0 !important;
    &_wrapper {
      text-transform: uppercase;
      font-family: "Source Sans Pro";
      font-size: 13px;
      display: flex;
      text-align: left;
      font-weight: 600;
      line-height: 16px;
      width: 100%;
      height: 100%;
      height: 70px;
      padding: 0 20px;
      cursor: pointer;
      &:hover {
        background-color: var(--color-gray);
      }
    }
    &_name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 20px;
    }
    &_sort {
      padding-left: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }

    &_sortIcon {
      transition: transform 0.2s ease-in-out;

      &_rotated {
        transform: rotate(180deg);
      }
    }
  }
  &_row {
    &_center {
      display: flex;
      font-size: 13px;
      text-align: center;
      line-height: 16px;
      display: flex;
      font-size: 13px;
      text-align: left;
      line-height: 16px;
      min-width: 40px;
      max-height: 47px;
      overflow: hidden;
    }
  }
}
.box {
  &_red {
    background-color: var(--color-red-row);
  }
  &_orange {
    background-color: var(--color-orange-row);
  }
  &_yellow {
    background-color: var(--color-yellow-row);
  }
  &_green {
    background-color: var(--color-green-row);
  }
}
