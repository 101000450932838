@import "@scss/components/variables.scss";

.container {
  position: relative;
  overflow: visible;
  cursor: default;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  .list {
    z-index: 100000;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1px;
    top: 120%;
    left: -20px;
    background: $white;
    border-radius: 4px;
    color: #626262;
    box-shadow: 0px 0px 4px rgba(199, 205, 230, 0.7);
    li {
      display: flex;
      gap: 10px;
      align-items: center;
      background: #eff1f8;
      padding: 5px 60px 5px 20px;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        background: rgb(220, 221, 225);
      }
      .radioCircle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $green;
        .innerCircle {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $green;
        }
      }
    }
  }
}
