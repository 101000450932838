.chatActions {
  display: flex;
  align-items: center;

  &__root {
    padding-top: 24px;

    &_mobile {
      padding: 18px 8px;
      padding-bottom: 20px;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 1px -1px 4px rgba(79, 79, 79, 0.25);
      border-radius: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__button {
    position: relative;
    height: 50px;
    min-height: 50px;
    width: 50px;
    flex-shrink: 0;
    padding: 0 !important;

    &_mobile {
      width: 44px;
      height: 44px;
      min-height: 44px;
    }
  }

  &__textInput {
    width: 100%;
    margin-top: 0 !important;
  }

  &__sendBtn {
    margin-left: 20px;
    margin-right: 10px;

    &_mobile {
      margin-left: 12px;
    }
  }
}

.fileInput {
  height: 100%;

  > input {
    display: none;
  }

  &__label {
    margin-right: 20px;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-dark);
    transition: 0.2 all ease;
    cursor: pointer;

    &:hover {
      background: var(--color-gray-dark);

      & :nth-child(1) {
        fill: var(--color-green);
      }
    }

    &_mobile {
      margin-right: 8px;
      background: var(--color-gray-dark);
      border-radius: 6px;
      height: 56px;
      width: 36px;
    }
  }
}
