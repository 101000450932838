.FeedBackBlock {
  margin-top: 30px;
  background-color: #fff;
  border-radius: 15px;
  min-height: calc(100vh - 300px);
  padding: 30px;

  &_mobile {
    padding: 24px 8px;
  }
}
