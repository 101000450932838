.row {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cell_section {
  cursor: pointer;
}
.section_row {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .arrow_drop__opened {
    transform: rotate(180deg);
  }

  &_title {
    display: inline-block;
    margin-left: 16px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    // @extend .font-semibold;
  }
}

.createTaskBtn {
  margin-left: 12px;
}

.container {
  background-color: var(--color-gray-dark);
  background-color: var(--color-white);
  padding-bottom: 20px;
}

.simplePage {
  min-height: 100px;
}

// :global(.MuiTableCell-head) {
//   & > :nth-child(1) {
//     height: 10px;
//   }
// }
.headFilter {
  display: flex;
  position: sticky;
  top: 56px !important;
  border: red solid;
  z-index: 1;
  background-color: var(--color-white);
  width: 100%;
}

.headTable {
  margin-top: -20px;
  height: 70px; // отвечает за высоту всего блока
  min-width: 90px;
}

.headTable_inCompany {
  height: 130px; // отвечает за высоту всего блока
}

.checkbox {
  display: flex;
  align-items: center;
  max-width: 160px;
}

.header_filters {
  position: absolute;
  background-color: var(--color-white);
  padding: 18px;
  margin-left: -16px;
  padding-right: 86px;
  display: flex;
  gap: 12px;
  align-items: center;
  overflow-x: auto;
  button {
    margin: 0 !important;
  }
  > * {
    width: 170px;
    flex: 0 0 170px;
  }
  .pickers_input {
    overflow-y: hidden !important;
    overflow-x: auto !important;
    // overflow: visible !important;
    height: 50px;
    border-radius: 4px;
    background-color: var(--color-gray-dark) !important;
    label {
      position: absolute !important;
      top: -6px !important;
      left: 16px !important;
    }
    input {
      height: 100% !important;
      padding: 16px 0px 17px 10px !important;
      &::placeholder {
        color: var(--color-dark) !important;
        font-size: 14px !important;
      }
    }
  }
}
.icon {
  cursor: pointer;
}

.content_table {
  border-top-left-radius: 15px;
  -webkit-top-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-top-bottom-right-radius: 15px;
  background-color: var(--color-white);
  overflow: hidden;
  min-height: 100px;
  button {
    margin: 0 8px 0 0 !important;
  }
  .firstCell {
    // padding: 0 0 0 95px;
  }
}

.cellHeadCompeny {
  margin-left: 20px;
}

.plan_top_content {
  position: absolute;
  top: 60px;
  margin-left: -10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // @extend .standard-content-padding;

  .download_plan_btn {
    height: 40px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    // @extend .colorful-btn;

    img {
      margin-left: 10px;
    }

    &:hover img {
      filter: invert(1) brightness(3);
    }
  }
}

.toggleAllGroups {
  all: unset;
  margin-right: 12px;
  cursor: pointer;
}

.selectedGroupsActions {
  display: flex;
  justify-content: flex-end;
}

.text-hidden {
  max-height: 100px;
  hyphens: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.cell_header {
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    font-size: 12px;
    // @extend .font-semibold;
  }
  > * {
    flex-shrink: 0;
  }
}
.cell_svg {
  position: relative;
  .toolTip {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
  // svg {
  //     position: absolute;
  //     top: 50%;
  //     right: 30px;
  //     transform: translateY(-50%);
  // }
}

.sort_icon {
  margin-left: 6px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: var(--color-gray-dark);
  }

  &_active {
    transform: rotate(180deg);
  }
}

body {
  margin: 0;
  padding: 2rem;
}

.table_wrap {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

.workplanTable {
  // min-height:calc(100vh - 100px);
  thead th {
    top: 0px;
  }
}

.container {
  background-color: var(--color-gray-dark);
  background-color: var(--color-white);
  padding-bottom: 20px;
}
.workplanTable {
  // min-height:calc(100vh - 100px);
  thead th {
    top: 0px;
  }
}

.simplePage {
  min-height: 100px;
}

// :global(.MuiTableCell-head) {
//   & > :nth-child(1) {
//     height: 10px;
//   }
// }
.headFilter {
  display: flex;
  position: sticky;
  top: 56px !important;
  border: red solid;
  z-index: 1;
  background-color: var(--color-white);
  width: 100%;
}

.headTable {
  margin-top: -20px;
  height: 70px; // отвечает за высоту всего блока
  min-width: 90px;
}

.headTable_inCompany {
  height: 130px; // отвечает за высоту всего блока
}

.checkbox {
  display: flex;
  align-items: center;
  max-width: 160px;
}

.header_filters {
  position: absolute;
  background-color: var(--color-white);
  padding: 18px;
  margin-left: -13px;
  padding-right: 86px;
  display: flex;
  gap: 12px;
  align-items: center;
  overflow-x: auto;
  button {
    margin: 0 !important;
  }
  > * {
    width: 170px;
    flex: 0 0 170px;
  }
  .pickers_input {
    overflow-y: hidden !important;
    overflow-x: auto !important;
    // overflow: visible !important;
    height: 50px;
    border-radius: 4px;
    background-color: var(--color-gray-dark) !important;
    label {
      position: absolute !important;
      top: -6px !important;
      left: 16px !important;
    }
    input {
      height: 100% !important;
      padding: 16px 0px 17px 10px !important;
      &::placeholder {
        color: var(--color-dark) !important;
        font-size: 14px !important;
      }
    }
  }
}
.icon {
  cursor: pointer;
}

.content_table {
  border-top-left-radius: 15px;
  -webkit-top-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-top-bottom-right-radius: 15px;
  background-color: var(--color-white);
  overflow: hidden;
  min-height: 100px;
  button {
    margin: 0 8px 0 0 !important;
  }
  .firstCell {
    // padding: 0 0 0 95px;
  }
}

.cellHeadCompeny {
  margin-left: 20px;
}

.plan_top_content {
  position: absolute;
  top: 60px;
  margin-left: -10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // @extend .standard-content-padding;

  .download_plan_btn {
    height: 40px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    // @extend .colorful-btn;

    img {
      margin-left: 10px;
    }

    &:hover img {
      filter: invert(1) brightness(3);
    }
  }
}

.toggleAllGroups {
  all: unset;
  margin-right: 12px;
  cursor: pointer;
}

.selectedGroupsActions {
  display: flex;
  justify-content: flex-end;
}

.text-hidden {
  max-height: 100px;
  hyphens: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.cell_header {
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    font-size: 12px;
    // @extend .font-semibold;
  }
  > * {
    flex-shrink: 0;
  }
}
.cell_svg {
  position: relative;
  .toolTip {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }
  // svg {
  //     position: absolute;
  //     top: 50%;
  //     right: 30px;
  //     transform: translateY(-50%);
  // }
}

.sort_icon {
  margin-left: 6px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: var(--color-gray-dark);
  }
}

body {
  margin: 0;
  padding: 2rem;
}

.table_wrap {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

////
///
///
///
.box {
  &_red {
    background-color: var(--color-red-row);
  }
  &_orange {
    background-color: var(--color-orange-row);
  }
  &_yellow {
    background-color: var(--color-yellow-row);
  }
  &_green {
    background-color: var(--color-green-row);
  }
}

.row {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cell_section {
  cursor: pointer;
}
.section_row {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &_title {
    display: inline-block;
    margin-left: 16px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    // @extend .font-semibold;
  }
}

// .createTaskBtn {
//   margin-left: 12px;
// }

.cell_border:not(:last-child) {
  border-right: 1px solid rgb(224, 224, 224);

  .resizer {
    z-index: 10;
    position: absolute;
    right: -5px;
    top: 0;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    background-color: transparent;
  }
}

.cell_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  > * {
    cursor: pointer;
  }
  img: {
    height: 21px !important;
    width: 21px !important;
  }
}
