.Loader {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.autoHeight {
    height: auto;
  }

  &.autoWidth {
    width: auto;
  }
}

.Loader__fullH {
  height: 100vh;
}
