.row {
  width: 100%;
  max-width: 350px;
  overflow: hidden;
}

.price {
  margin-top: 20px;
  min-height: 40px;
}

.icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word {
  width: 300px;
  max-width: 300px;
  word-wrap: break-word;
}

.title {
  display: flex;
  text-align: center;
  min-height: 70px;
  flex-direction: column;
}

.box {
  &_close {
    height: 405px;
    overflow: hidden;
  }
}
