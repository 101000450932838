.modal {
  &_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 4px;
    min-width: 0px;
    width: fit-content;
    max-height: calc(100vh - 0px);
    min-height: 0px;
  }
  &_close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
  }
}

@keyframes view {
  0% {
    transform: scale(0.995);
  }
  100% {
    transform: scale(1);
  }
}
