.Title {
  display: inline-block;
  line-height: 120%;
  font-size: 40px;
  letter-spacing: 0em;
  font-family: var(--font-ssp);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Title {
    font-size: 25px;
  }
}

@media screen and (max-width: 767px) {
  .Title {
    font-size: 18px;
    line-height: 21px;
  }
}
