.contain {
  margin-top: 20px;
  max-width: 700px;
  display: flex;
  & > :nth-child(1) {
    margin-right: 20px;
  }
}

.text__path {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: 10px;
    left: -10px;
    width: 4px;
    height: 4px;
    border-radius: 99999px;
    background-color: var(--color-gray-darker2);
  }
}

.buttons {
  margin-top: 40px;
  display: flex;
  & > :nth-child(1n) {
    margin-right: 20px;
  }
}
