.submit_btn {
  width: 100%;
  margin: 20px 0 10px;
}

.buttons {
  margin-top: 20px;
  & > :nth-child(1) {
    margin-bottom: 20px;
  }
}

.buttonOptions {
  width: 100%;
}
