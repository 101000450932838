@import "@scss/components/variables.scss";

.dropDown_container {
  flex: 1 1 100%;
  height: 48px;
  width: 100%;
  position: relative;
  .dropDown {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 14px !important;
    align-items: center;
    padding: 10px 30px 10px 16px;
    background-color: $gray-dark;
    border-radius: 4px;
    font-family: inherit;
  }
  .arrow_icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    &.arrow_icon__opened {
      transform: rotate(180deg);
    }
  }
}
