.query_info {
  margin-top: 24px;
  &__head {
    display: flex;
    align-items: center;
  }
}

.edit {
  cursor: pointer;
  margin-left: 12px;
}

.download {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.legal_entities {
  margin-top: 14px;
  min-height: 20px;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  min-width: 300px;
  margin-left: -20px;
  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
}

.line_vertical {
  min-height: 10px;
  width: 1px;
  background-color: #d7dced;
}
