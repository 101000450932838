.modal {
  max-width: 600px;
  width: 100%;

  &__title {
    text-align: center;
    margin: 12px 0;
  }

  &__factorsTable {
    margin-top: 12px;
  }
}
