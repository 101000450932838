@import "../variables";

.tarrifButton {
  padding: 6px 12px;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
  white-space: nowrap;
  background-color: $green;
  font-weight: 500;
  color: $white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell {
  margin-left: 20px;
}

.select {
  *:active,
  *:focus,
  * {
    border: none !important;
  }
}

.pickers_input {
  overflow: hidden !important;
  border-radius: 4px;
  height: 42px;
  width: 160px;
  background-color: $gray-dark !important;
  * {
    border: none;
    &::after {
      border: none;
    }
  }
  input {
    overflow-y: hidden !important;
    padding: 2px 0 2px 15px !important;
    width: 100%;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    transform: scale(0.8);
  }
}

.dateError {
  border: 1px solid var(--color-red);
}

.search {
  position: relative;
  width: 100%;
  height: 0;
  > div {
    position: absolute;
    bottom: calc(100% + 13px);
    right: 40px;
  }
}
.table {
  td {
    text-align: left;
  }
}
.edit_icon {
  filter: invert(1);
  -ms-filter: invert(1);
  -webkit-filter: invert(1);
}
