.wrapper {
  margin-top: 20px;
}

.buttons {
  margin-top: 20px;
  & > :nth-child(1) {
    margin-bottom: 20px;
  }
}

.buttonOptions {
  width: 100%;
}

.passwordMask {
  margin-top: 20px;
  margin-bottom: 20px;
}
