:root {
  --color-green: #00b856;
  --color-green-darker: #007d3b;
  --color-white: #ffffff;
  --color-yellow: #ffff00;
  --color-black: #000000;
  --color-orange: #ffaa2b;
  --color-red: #e24826;
  --color-dark: #373737;
  --color-gray: #eff1f8;
  --color-blue: #1c6df9;
  --color-blue-light: #76c6f7;
  --color-blue-pastel: #68b8f1;
  --color-blue-tarrif: #59d1f8;
  --color-purple-tarrif: #8250be;
  --color-purple: #4b3eff;
  --color-red: #e57373;
  --color-red-light1: #ff7975;
  --color-red-light: rgba(229, 115, 115, 0.18);
  --color-red-light-hovered: rgba(229, 115, 115, 0.2);
  --color-gray-dark: #f5f6fa;
  --color-gray-darker2: #8d96a5;
  --color-gray-dark3: #d7dced;
  --color-gray-darker: #545050;
  --color-gray-dark2: #807d7d;
  --font-ssp: "Source Sans Pro", sans-serif;
  --font-pf-light: "PF Centro Sans Pro Light", sans-serif;
  --color-svg-blue-dark: #111ab1;
  --color-svg-blue: #0020fa;
  --color-svg-orange: #bc8a00;
  --color-svg-red: #770909;
  --color-svg-green: #46cf3c;
  --color-svg-gray: #92929267;
  --color-svg-gray-dark: #464646d4;
  --color-svg-green-dark: #39a431;
  --color-red-row: #ffe1e1;
  --color-yellow-row: #ffffaf;
  --color-orange-row: #ffe9c1;
  --color-green-row: #e6f6cf;
  --front-url: "https://lk.0tservice.ru";
  --img-path: "https://lk.0tservice.ru/static/";
}
// РЕФАКТОР (убрать)
$green: #00b856;
$green-darker: #007d3b;
$yellow-light: #ffff00;
$yellow: #ffaa2b;
$red: #e24826;
$dark: #373737;
$gray: #eff1f8;
$gray-dark: #f5f6fa;
$gray-200: #eeeeee;
$blue: #1c6df9;
$blue-light: #76c6f7;
$blue-pastel: #68b8f1;
$blue-tarrif: #59d1f8;
$purple-tarrif: #8250be;
$purple: #4b3eff;
$red: #e57373;
$red-light1: #ff7975;
$red-light: rgba(229, 115, 115, 0.18);
$red-light-hovered: rgba(229, 115, 115, 0.2);
$gray-darker2: #8d96a5;
$gray-dark3: #d7dced;
$gray-darker: #545050;
$gray-dark2: #807d7d;
$white: #ffffff;
$ff-ssp: "Source Sans Pro", sans-serif;
$ff-pf-light: "PF Centro Sans Pro Light", sans-serif;
$front_url: "https://lk.0tservice.ru";
$imgPath: "https://lk.0tservice.ru/static/";

.white-btn {
  min-width: max-content;
  height: 40px;
  background: #fff;
  border-radius: 100px;
  overflow: hidden;
  font-family: $ff-pf-light;
  font-size: 16px;
  color: #7a7a7a;
  border: 1px solid #e2e5f1;
  -webkit-transition: box-shadow 250ms;
  -moz-transition: box-shadow 250ms;
  -o-transition: box-shadow 250ms;
  transition: box-shadow 250ms;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
  }
}

.green-btn {
  min-width: max-content;
  height: 40px;
  background: $green;
  border-radius: 100px;
  overflow: hidden;
  font-family: $ff-pf-light;
  font-size: 16px;
  color: #fff;
  -webkit-transition: box-shadow 250ms;
  -moz-transition: box-shadow 250ms;
  -o-transition: box-shadow 250ms;
  transition: box-shadow 250ms;
  padding: 0 20px;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  }
}

.small-green-btn {
  padding: 0 30px;
  @extend .green-btn;
}

.colorful-btn {
  width: auto;
  height: 32px;
  border-radius: 1000px;
  color: $dark;
  background: #fff;
  font-family: $ff-pf-light;
  font-size: 16px;
  border: 1px solid #e2e5f1;
  @extend .transition-all;

  &:hover,
  &.active {
    color: #fff;
    background: $green;
    @extend .shadow-md;
  }

  &:focus {
    outline: none;
  }
}

.large-colorful-btn {
  height: 40px;
  @extend .colorful-btn;
}

.small-colorful-btn {
  padding: 0 18px;
  @extend .colorful-btn;
}

.green-colorful-btn {
  color: #fff;
  background: $green;
  @extend .colorful-btn;

  &:hover,
  &.active {
    @extend .shadow-md;
  }
}

.red-colorful-btn {
  color: var(--color-white);
  background: $red;
  @extend .colorful-btn;

  &:hover,
  &.active {
    color: #fff;
    background: $red;
    @extend .shadow-md;
  }
}

.bordered-btn {
  width: max-content;
  height: 32px;
  text-align: center;
  color: $gray-darker;
  border: 1px solid $gray;
  border-radius: 15px;
  overflow: hidden;

  &.active,
  &:hover {
    color: $dark;
    border-color: $green;
  }

  &:focus {
    outline: none;
  }
}

.small-bordered-btn {
  height: max-content;
  padding: 3px 15px;
  @extend .bordered-btn;
}

.w-full {
  width: 100%;
}

.hidden {
  display: none;
}

.standard-content-padding {
  padding: 24px;
}

.small-content-padding {
  padding: 16px;
}

.tiny-content-padding {
  padding: 12px;
}

@media (max-width: 768px) {
  .standard-content-padding {
    padding: 20px 15px 15px;
  }
}

@media (max-width: 480px) {
  .standard-content-padding {
    padding: 15px 10px 10px;
  }
}

.border-radius {
  border-radius: 5px;
}

// FLEX

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-n-c {
  display: flex;
  align-items: center;
}

.flex-c-n {
  display: flex;
  justify-content: center;
}

// BORDER-RADIUS & MARGINS/PADDINGS

.rounded-md {
  border-radius: 5px;
}

.rounded-xl {
  border-radius: 10px;
}

.mt-25 {
  margin-top: 25px;
}

//.mt-10 {
//  margin-top: 10px;
//}

//.my-25 {
//  margin: 25px 0;
//}

//.my-6 {
//  margin-top: 6px;
//  margin-bottom: 6px;
//}

//.mr-10 {
//  margin-right: 10px;
//}

//.mb-3 {
//  margin-bottom: 3px;
//}

//.mb-5 {
//  margin-bottom: 5px;
//}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-auto {
  margin-left: auto;
}

.px-10 {
  padding: 0 10px;
}

.px-12 {
  padding: 0 12px;
}

.px-20 {
  padding: 0 20px;
}

//.space-x-5 > * + * {
//  margin-left: 10px !important;
//  margin-right: 10px !important;
//}

//.space-x-10 > * + * {
//  margin-left: 10px;
//  margin-right: 10px;
//}

// BACKGROUNDS

.bg-white {
  background-color: #fff;
}

// FONTS

.font-lg {
  font-size: 18px;
}

.font-xl {
  font-size: 28px;
}

.font-normal {
  font-weight: 400;
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

// SHADOW

.shadow-md {
  -webkit-box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.2);
  -moz-box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.2);
  -o-box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.2);
  box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.2);
}

.shadow-sm {
  -webkit-box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.1);
  -moz-box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.1);
  -o-box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.1);
  box-shadow: 1px 2px 5px rgba(79, 79, 79, 0.1);
}

// ANIMATION

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover-animation-card {
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-4px);
    -webkit-box-shadow: 2px 2px 15px rgba(79, 79, 79, 0.2) !important;
    -moz-box-shadow: 2px 2px 15px rgba(79, 79, 79, 0.2) !important;
    -o-box-shadow: 2px 2px 15px rgba(79, 79, 79, 0.2) !important;
    box-shadow: 2px 2px 15px rgba(79, 79, 79, 0.2) !important;
  }
}
