.wrapper {
  width: 100%;
}

.choise_programm {
  display: flex;
  flex-wrap: wrap;
  & > :nth-child(1n) {
    margin-bottom: 10px;
    margin-right: 8px;
  }
}

.modal {
  width: 400px;
  padding: 20px 30px;
  display: block;
  &_head {
    margin-bottom: 24px;
  }
  &_input {
    display: flex;
    width: 100%;
    flex-direction: column;
    & > :nth-child(1n) {
      margin-bottom: 24px;
    }
  }
  &_buttons {
    display: flex;
    justify-content: flex-end;
    & > :nth-child(1) {
      margin-right: 20px;
    }
  }
}
