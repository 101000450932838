.medicalExamControls {
  &__content {
    margin-top: 20px;
  }

  &__downloadActions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin: 20px 0;
  }

  &__downloadBtn {
    all: unset;
    cursor: pointer;
    color: var(--color-green);
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin: 12px 0;
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}
