.signingControlJournal {
  overflow-x: auto;
  max-width: 100dvw;

  &__filters {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__downloadBtn {
    margin-top: 24px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}

.selectDate {
  padding-top: 20px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  background-color: var(--color-gray-dark);
  border-bottom: #00000069 solid 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  width: 206px;
  height: 54px;
  font-size: 15px;
  font-weight: 500;
  position: relative;

  &:hover {
    border-bottom: #000000c7 solid 1px;
  }

  &__label {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 13px;
    color: #5f5f5fdd;
  }

  &__icon {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
