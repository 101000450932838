.EmployeesInfo {
  margin-top: 12px;

  &__controlBtn {
    margin-top: 12px;
  }

  &__departmentLink {
    margin-top: 12px;
  }
}

.EmployeesInfoButton {
  display: flex;
  // border: red solid;
  align-items: center;
  height: 70px;
  & > :nth-child(1n) {
    margin-right: 20px;
  }
}
