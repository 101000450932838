.CategoryTable {
  border: 1px solid var(--color-gray);
  border-radius: 12px;
  box-shadow: 0px 0px 4px 0px #dfe3f3b2;
  padding: 15px 24px;
  overflow-x: auto;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__studyUrl {
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__hr {
    color: var(--color-gray);
    height: 1px;
    width: 100%;
    margin-top: 23px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 24px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__itemLeft {
    display: flex;
    align-items: center;
    min-width: 400px;
  }

  &__itemBtn {
    white-space: nowrap;

    &:not(:disabled):hover {
      background-color: var(--color-green);
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 768px) {
  .CategoryTable {
    padding: 12px;

    &__items {
      margin: 12px 0;
    }
  }
}
