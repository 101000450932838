.content {
  width: 100%;
  min-height: calc(100vh - 320px);
  padding: 24px;
  margin-bottom: 200px;
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &_direction {
    width: 100%;
  }
  &_right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & > :nth-child(1n) {
      margin-bottom: 28px;
    }
  }
  &_select {
    margin-top: 12px;
  }
}

.button_next {
  width: 251px;
}

.tables {
  margin-top: 40px;
}
