@import "../../scss/components/variables";

.container {
  width: 350px;
  height: 40px;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 0 12px;
    padding-right: 50px;
  }
  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
.gray {
  background-color: $gray-dark;
}
.white {
  background-color: $white;
}

@media (max-width: 1440px) {
  .container {
    width: 114px;
  }
}
