@import "../components/variables";
@import "../components/fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.document_editor_container {
  width: 95vw;
  height: 95vh;
  max-width: max-content;
}

.document_editor {
  width: 94vw;
  height: 100%;
}

.document_editor iframe {
  pointer-events: all;
}

.document_editor+button {
  top: 0px;
  right: 30px;
  svg path {
    fill: #dae1ea;
    stroke: #b2b8bf;
  }
}

@media (max-width: 768px) {
  .document_editor {
    // width: 100%;
  }
}
