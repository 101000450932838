@import "../../components/variables";

.workspaceDocumentModal {
  width: 650px;

  // сделал 310  - сказали уменьшить область. Фиксирую замечание
  height: auto;
}

.formFilesList {
  width: 100%;
}

.formFileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $gray-dark;
  padding: 0 10px;
  height: 32px;
  margin-top: 10px;
  border-radius: 10px;
  color: $dark;
}

.deleteFile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2d0d0;
  height: 20px;
  width: 20px;
  border-radius: 99999px;
}

.formFile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: $ff-ssp;
  font-size: 14px;

  & img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
  }

  & svg {
    width: 17px;
    min-width: 17px;
    height: 17px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
    margin: 0 9px;
  }
  margin-right: 20px;
  overflow: hidden;
  cursor: default;

  &__name {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__fileSize {
    white-space: nowrap;
  }
}

.file_icon {
  margin-right: 10px;
}

.workspaceDocumentModalForm {
  margin-top: 20px;
}

.periodSelect {
  min-width: 285px !important;
}

.listLoadFile {
  overflow-y: scroll;
  height: 200px;
}

@media (max-width: 768px) {
  .workspaceDocumentModal {
    min-width: 310px;
    width: 100%;
  }

  .workspaceDocumentModalForm {
    margin-top: 10px;
  }
}
