@import "../../components/variables";

.calendar_section {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  gap: 2rem;
  flex-wrap: nowrap;
  max-width: 100%;
  @extend .standard-content-padding;
}
.checkbox {
  margin: 10px 0;
}

.displayNotificationsCheckbox {
  margin: 0 12px;
}

.calendar_panel {
  flex-basis: calc((100% - 4rem) / 2);
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  .calendar_day_item {
    position: relative;
    background: none;
    border: none;
    border-bottom: 1px solid #eff1f8;
    cursor: pointer;

    &:hover {
      border: 1px solid $green;
      border-radius: 5px;
    }

    &.active {
      background-color: #eff1f8;
    }

    .event_block_financialIcon {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 18px;
      height: 18px;
    }

    .event_block {
      position: absolute;
      left: 50%;
      bottom: 10px;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;

      .calendar_event {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: $gray-darker;
        margin: 0 3px;
        opacity: 0;

        &.active {
          opacity: 1;
        }

        &.green {
          opacity: 1;
          background: $green;
        }

        &.yellow {
          opacity: 1;
          background: $yellow;
        }

        &.red {
          opacity: 1;
          background: $red;
        }
      }
    }
  }

  .calendar_control__btn {
    width: 15px;
    height: 15px;
  }

  button:focus {
    outline: none;
  }

  .calendar_control__panel {
    width: 100%;
    height: 70px;
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;

    .filter_btns {
      display: flex;
      align-items: center;
      gap: 1rem;
      .filter_btn {
        flex-basis: 200px;
        flex-grow: 0;
        justify-self: flex-start;
      }
    }

    .dateFilter_btns {
      display: flex;
      align-items: center;
    }
    .calendar_month_panel {
      width: 90px;
      position: relative;
      margin-right: 25px;
    }

    .calendar_year_panel {
      width: 55px;
      position: relative;
    }

    .calendar_option_list {
      position: absolute;
      left: 0;
      bottom: -15px;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%);
      width: 100%;
      display: none;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid $gray-dark;
      border-radius: 5px;
      overflow-y: auto;
      height: auto;
      max-height: 300px;
      font-family: $ff-ssp;
      color: $dark;
      z-index: 1;
      line-height: 30px;
      padding: 5px 0;

      &.active {
        display: flex;
      }

      button {
        text-align: left;
        padding-left: 10px;
        &.active {
          color: $green;
        }
      }
    }

    .year_btn,
    .month_btn {
      font-family: $ff-ssp;
      color: $dark;
      font-weight: 600;
      font-size: 18px;
      margin: 0 10px;
      display: flex;
      align-items: center;

      .calendar_control__arrow_down {
        position: relative;
        margin-top: 2px;
        margin-left: 12px;
      }
    }
  }
}

.events_panel {
  flex-basis: calc(100% / 2 - 2rem);
  max-width: calc(100% / 2 - 2rem);
  flex-grow: 0;
  flex-shrink: 0;

  // EVENTS PANEL -> TOP PANEL
  .events_panel__top_content {
    width: 100%;
    max-width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;

    .today_text {
      font-family: $ff-ssp;
      color: $green;
      font-weight: 600;
      font-size: 18px;
      text-decoration: underline;
    }

    .selected_calendar_date {
      display: flex;
      align-items: center;

      .selected_calendar_date__text {
        font-family: $ff-ssp;
        font-weight: 600;
        font-size: 18px;
        color: $dark;
        position: relative;
        margin: -2px 24px 0;
      }

      button {
        position: relative;

        &:focus {
          outline: none;
        }
      }

      .selected_calendar_date__prev_btn {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      .selected_calendar_date__next_btn {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
    }

    .calendar_create_event_btn {
      white-space: nowrap;
      height: 40px;
      padding: 0 23px;
      display: flex;
      align-items: center;
      @extend .green-colorful-btn;

      img {
        margin-left: 5px;
      }
    }
  }

  //  EVENTS PANEL -> EVENTS CONTENT
  .events_panel__event_items {
    width: 100%;
    overflow-y: scroll;
    height: 450px;
    padding: 0 0.4rem;

    .event_item {
      width: 100%;
      max-width: 100%;
      height: max-content;
      border: #fff;
      margin-top: 16px;
      border-radius: 15px;
      cursor: pointer;
      overflow: hidden;
      @extend .shadow-md, .standard-content-padding;

      .event_item__top_panel {
        width: 100%;
        min-height: 24px;
        height: max-content;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .event_title {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          color: $dark;
          font-family: $ff-ssp;
          overflow-wrap: break-word;
          max-height: 100px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          img {
            display: inline-block;
            width: 24px;
            margin-right: 12px;
          }
        }

        .event_item__buttons {
          display: flex;
          min-width: max-content;

          .event_item__button {
            margin: 0 10px;

            &:last-child {
              margin-right: 0;
            }

            &:focus {
              outline: none;
            }

            img {
              width: 20px;
              height: auto;
            }
          }
        }
      }

      .event_item__date {
        font-family: $ff-ssp;
        font-size: 16px;
        color: $gray-darker;
        margin-top: 4px;
      }

      .event_item__desc {
        font-size: 16px;
        color: $dark;
        line-height: 20px;
        margin-top: 8px;
        overflow-wrap: break-word;
        max-height: 100px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .event_item__resignature_btn {
        @extend .colorful-btn;
        padding: 0 15px;
        height: 40px;
        margin-top: 8px;
      }
    }
  }
}

@media (max-width: 1500px) {
  .calendar_section {
    flex-direction: column;
  }
  .events_panel {
    // EVENTS PANEL -> TOP PANEL
    .events_panel__top_content {
      .today_text {
        font-size: 17px;
      }

      .selected_calendar_date {
        .selected_calendar_date__text {
          font-size: 17px;
          margin: -2px 5px 0;
        }
      }

      .calendar_create_event_btn {
        padding: 0 16px;
      }
    }
  }

  .calendar_panel {
    flex-basis: 100%;
    width: 100%;
  }

  .events_panel {
    width: 100%;
    max-width: none;

    // EVENTS PANEL -> TOP PANEL
    .events_panel__top_content {
      flex-direction: row;
      height: 70px;
      padding: 0;
      margin-top: 24px;

      .selected_calendar_date {
        .selected_calendar_date__text {
          margin: -2px 24px 0;
        }
      }
    }
  }
}

@media (min-width: 400px) and (max-width: 650px) {
  .events_panel {
    // EVENTS PANEL -> TOP PANEL
    .events_panel__top_content {
      flex-direction: column;
      height: max-content;
      padding: 0 0 16px;
    }

    .events_panel__event_items {
      width: 100%;

      .event_item {
        padding-left: 15px;
        padding-right: 15px;

        .event_item__top_panel {
          .event_title {
            font-size: 18px;
            line-height: 20px;
          }

          .event_item__buttons {
            .event_item__button {
              margin: 0 8px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .events_panel {
    // EVENTS PANEL -> TOP PANEL
    .events_panel__top_content {
      flex-direction: column;
      height: max-content;
      padding: 0 0 16px;
    }

    .events_panel__event_items {
      width: 100%;

      .event_item {
        @extend .shadow-md, .standard-content-padding;

        .event_item__top_panel {
          .event_title {
            font-size: 17px;
            line-height: 20px;
          }

          .event_item__buttons {
            .event_item__button {
              margin: 0 8px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
