.chatHeader {
  display: flex;
  justify-content: space-between;

  &__info {
    display: flex;
    align-items: center;
  }

  &__container {
    margin-bottom: 16px;

    &_mobile {
      margin-bottom: 10px;
      padding: 0 6px;
    }
  }
}

.closedStatus {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 30px;

  &_mobile {
    justify-content: space-between;
    column-gap: 4px;
    margin-top: 12px;
  }

  &__message {
    color: var(--color-green);
    border: var(--color-green) solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    border-radius: 9999px;
    cursor: default;
    flex-shrink: 0;
  }
}

.date {
  display: flex;
  align-items: center;
}
