.MaterialsPublicTab {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.wrapper {
  margin-top: 20px;
  min-height: calc(100vh - 400px);
  display: flex;
  &_withMargin {
    margin-top: 40px;
  }
}
.listCategory {
  width: 100%;
}

.listCategoryItem {
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.room {
  height: calc(100vh - 400px);
  width: 640px;
  overflow-y: scroll;
  padding: 0 20px;
}

.wrapperScroll {
  padding: 0 20px;
  overflow-y: scroll;
  height: calc(100vh - 400px);
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  &__divider {
    width: 2px;
    background-color: var(--color-gray);
  }

  &__content {
    width: 100%;
    &_clipped {
      max-width: 60%;
    }
  }
}

.headerCategory {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    white-space: nowrap;
  }
}
.publicMaterial {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.roomSection {
  margin-top: 20px;
}

.roomMessage {
  margin-top: 12px;
  max-width: 500px;
}
.educationalPromotionRoom {
  background: #ffffff;
  margin-top: 80px;
  overflow: hidden;
  border: 2px solid var(--color-green);
  border-radius: 12px;

  img {
    width: 100%;
    object-fit: contain;
    object-position: top center;
  }
}

.recomendations {
  margin-top: 30px;
  margin-bottom: 200px;
}
