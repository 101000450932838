.signingControlJournalTable {
  &__employeeName {
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }

  &__filename {
    width: 400px;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  }

  &__indexCell {
    white-space: nowrap;
  }

  &__headCell {
    text-transform: uppercase;
    font-family: "Source Sans Pro";
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    line-height: 16px;
    height: 70px;
    padding: 20px 4px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray);
    }
  }

  &__sort {
    padding-left: 20px;
  }

  &__sortIcon {
    transition: transform 0.2s ease-in-out;

    &_rotated {
      transform: rotate(180deg);
    }
  }
}
