.modal {
  width: 400px;
  padding: 20px 30px;
  display: block;

  &__form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__input {
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 24px;
  }
}
