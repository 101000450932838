.wrapper {
  margin-bottom: 30px;
}

.contain_title {
  height: 58px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    margin-right: 24px;
    cursor: pointer;
  }
}

.ADD {
  background-color: var(--color-green-row);
}

.EDIT {
  background-color: var(--color-yellow-row);
}

.DELETE {
  background-color: var(--color-red-row);
}

.title {
  height: 100%;
  display: flex;
  align-items: center;
  & > :nth-child(2) {
    cursor: pointer;
    margin-top: 5px;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.table {
  &_head {
    margin: 0;
    padding: 0 10px 0 0 !important;
    &_wrapper {
      text-transform: uppercase;
      font-family: "Source Sans Pro";
      font-size: 13px;
      display: flex;
      text-align: left;
      font-weight: 600;
      line-height: 16px;
      width: 100%;
      height: 100%;
      height: 70px;
      padding: 0 20px;
      cursor: pointer;
    }
    &_name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 20px;
    }
    &_sort {
      padding-left: 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.add_programm {
  margin-top: 30px;
}
