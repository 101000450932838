.wrapper {
  min-height: calc(100vh - 500px);
}

.backButton {
  margin-left: 24px;
  margin-top: 24px;
}

.tableWrapper {
  border-top: 1px solid #e9eaf1;
}

.table_head {
  &_wrapper {
    margin-left: 20px;
  }
}

.list_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.creation_btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  float: right;
  margin: 24px 14px;
}
